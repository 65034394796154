import axios from "axios"


export const getGoalsByGuest = (guestId, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `wellness-goals/${guestId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const updateGoalsByGuest = (guestId, item, token) => {
  const data = {
    ...item
  }
  return axios.put(process.env.VUE_APP_API_URL + `wellness-goals/${guestId}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}
