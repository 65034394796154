import axios from "axios"

export const getAllByGuestId = (guestId, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `guesthealthstatuses/guest/${guestId}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const create = (guestId, item, token) => {
  const data = {
    ...item,
    guestId
  }
  return axios.post(process.env.VUE_APP_API_URL + `guesthealthstatuses`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `guesthealthstatuses/` + id, {
    ...item
  }, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `guesthealthstatuses/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const get = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `guesthealthstatuses/?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `guesthealthstatuses/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}