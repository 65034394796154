


<template>
  <modal :show="showModal">
    <b-overlay :show="isBusy">
      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <b-form
          class="needs-validation"
          @submit.prevent="handleSubmit(itemFormSubmit)"
          novalidate
        >
          <div class="">
    

            <b-row>
              <b-col lg="12">
                <base-input
                  type="select"
                  :required="true"
                  label="Advisor"
                  :name="'Advisor'"
                  placeholder="Select advisor"
                  v-model="itemForm.advisorId"
                  :options="advisors"
                  :key-field="'_id'"
                  :value-field="'_id'"
                  :text-field="'name'"
                ></base-input>
              </b-col>

              <b-col lg="12">
                <base-input
                  type="select"
                  :required="true"
                  label="Retreat"
                  :name="'Retreat'"
                  placeholder="Select retreat"
                  v-model="itemForm.retreatId"
                  :options="retreats"
                  :key-field="'_id'"
                  :value-field="'_id'"
                  :text-field="'name'"
                ></base-input>
              </b-col>

              <b-col lg="12">
                <base-input
                  type="number"
                  :name="'Guests'"
                  label="Guests"
                  :required="true"
                  placeholder="Guests"
                  v-model="itemForm.guests"
                >
                </base-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="12">

                 <base-datetime-input
                    :required="true"
                    name="Check-in"
                    label="Check-in"
                    v-model="itemForm.checkin"
                    >
                    </base-datetime-input>

              </b-col>
              <b-col lg="12">
                 <base-datetime-input
                    :required="true"
                    name="Check-out"
                    label="Check-out"
                    v-model="itemForm.checkout"
                    >
                    </base-datetime-input>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>

    <template slot="footer">
      <div>
        <base-button @click="close" type="link" class="ml-auto"
          >Cancel</base-button
        >
        <base-button @click="itemFormSubmit" type="primary">Update</base-button>
      </div>
    </template>
  </modal>
</template>






<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';

import { dateformatter } from "@/util/dateformatter";
import * as StayRepo from "@/api-repo/stays";
import * as PersonnelRepo from "@/api-repo/personnel";
import * as RetreatRepo from "@/api-repo/retreats";
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import { handleError, successMessage } from "@/util/error-handler";

export default {
  name: "modal-guest-stay-edit",
  inject: ["userStore"],
  components: {baseDatetimeInput},
  props: ["show", "showModal"],
  data() {
    return {
      isBusy: false,
      advisors: [],
      retreats: [],
      itemForm: {
        advisorId: null,
        retreatId: null,
        guests: null,
        checkin: null,
        checkout: null,
      },
    };
  },

  created() {
    this.getInitData();

    this.getData();
  },
  /*

media_id: 
    title: {en: , ar: }
    text: {en: , ar: }
    target_id: 
    target_type: 
    hidden: 
    tags: []
  */
  methods: {
    dateformatter: dateformatter,
    generateBreadCrumb() {
      return [{ label: "media" }];
    },
    getInitData: async function () {
      let advisorResult = await PersonnelRepo.getAdvisors(
        this.userStore.state.token
      );
      let retreatResult = await RetreatRepo.getList(this.userStore.state.token);
      this.advisors = advisorResult.data.data;
      this.retreats = retreatResult.data.data;
    },



    close(){
      this.$emit('closeModal');
    },
    getData: async function () {
      try {
        this.isBusy = true;
        let result = await StayRepo.getById(
          this.$route.params.stayId,
          this.userStore.state.token
        );
        this.itemForm.checkin = dateformatter(result.data.data.checkin);
        this.itemForm.checkout = dateformatter(result.data.data.checkout);
        this.itemForm.advisorId = result.data.data.advisorId;
        this.itemForm.retreatId = result.data.data.retreatId;
        this.itemForm.guests = result.data.data.guests;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },
    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;
        try {
          this.isBusy = true;
          await StayRepo.update(
            this.$route.params.stayId,
             this.$route.params.id,
            this.itemForm,
            this.userStore.state.token
          );
          console.log(this.$route.params.id)
          successMessage("Item updated successfully");
          this.close();
        } catch (e) {
          handleError(e);
        } finally {
           this.$emit('refresh');
          this.isBusy = false;
        }
      }
    },
  },
};
</script>