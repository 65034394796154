<template>
  <div>

    <b-overlay :show="isBusy">
      <b-card class="pt-0">

        <b-card-header class="pt-0 pl-0 border-0">
          <a role="button" @click="$router.go(-1)" class="small pointer">
            <i class="mr-1 fas fa-angle-left" />Wellness Plans
          </a>
        </b-card-header>

        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <b-form class="needs-validation" @submit.prevent="handleSubmit(itemFormSubmit)" novalidate>
            <div>
              <b-row>
                <b-col lg="6">
                  <base-input type="select" :required="true" label="Stay" :name="'Stay'" placeholder="Select Stay"
                    v-model="itemForm.stayId" :options="mappedStays" :key-field="'_id'" :value-field="'_id'"
                    :text-field="'name'"></base-input>
                </b-col>
              </b-row>

              <b-row>

                <b-col lg="6">
                  <base-input type="select" :required="true" label="Type" :name="'Type'" placeholder="Select type"
                    v-model="itemForm.type" :options="types" :key-field="'_id'" :value-field="'_id'"
                    :text-field="'name'"></base-input>
                </b-col>

                <b-col lg="6">
                  <base-input type="select" :required="true" label="Advisor" :name="'Advisor'"
                    placeholder="Select advisor" v-model="itemForm.advisorId" :options="advisors" :key-field="'_id'"
                    :value-field="'_id'" :text-field="'name'"></base-input>
                </b-col>



              </b-row>
              <b-row>
                <b-col v-if="itemForm.type == 'video'" lg="6">
                  <base-multi-select name="Video" :required="true" ref="selectedvideos" v-model="selectedVideo"
                    :options="mappedVideos" :multiple="false" placeholder="Select Video" label="Video" :trackby="'value'">
                  </base-multi-select>
                </b-col>

                <b-col v-if="itemForm.type == 'nutrition'" lg="6">
                  <base-multi-select name="Recipe" ref="selectedrecipes" v-model="selectedRecipe" :required="true"
                    :options="mappedRecipes" :multiple="false" placeholder="Select Recipe" label="Recipe"
                    track-by="value">
                  </base-multi-select>
                </b-col>

                <b-col lg="6">
                  <base-input :name="'Notes'" :required="itemForm.type == 'drnote' ? true : false" label="Notes"
                    :rows="'5'" placeholder="Notes" :type="'textarea'" v-model="itemForm.notes">
                  </base-input>
                </b-col>

              </b-row>
              <b-row>
                <b-col lg="6">
                  <base-datetime-input :required="false" name="Start Date" label="Start Date" v-model="itemForm.starting">
                  </base-datetime-input>


                </b-col>

                <b-col lg="6">
                  <base-datetime-input :required="false" name="End Date" label="End Date" v-model="itemForm.ending">
                  </base-datetime-input>


                </b-col>


                <b-col lg="6">
                  <base-input type="select" :required="true" label="Frequency" :name="'Frequency'"
                    placeholder="Select frequency" v-model="itemForm.frequency" :options="frequencies" :key-field="'_id'"
                    :value-field="'_id'" :text-field="'name'"></base-input>
                </b-col>



              </b-row>


            </div>

            <base-button type="primary" native-type="submit" class="my-4">
              {{ isUpdate() ? "Update" : "Create" }}
            </base-button>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>

  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import * as WellnessPlanResultRepo from "@/api-repo/wellnessplans";
import * as PersonnelRepo from "@/api-repo/personnel";
import * as VideosRepo from "@/api-repo/videos";
import * as RecipesRepo from "@/api-repo/recipes";
import * as stayRepo from "@/api-repo/stays";
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import { dateformatter, dateformatterWithTime} from "@/util/dateformatter";


import BaseMultiSelect from "@/components/Inputs/BaseMultiSelect.vue";
import MediaGalleryInput from "@/components/Inputs/MediaGalleryInput.vue";
import { handleError, successMessage } from "@/util/error-handler";

export default {
  name: "stay-edit",
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    MediaGalleryInput,
    BaseMultiSelect,
    baseDatetimeInput
  },
  props: ["type"],
  watch: {
    mappedVideos(newv, oldv) {
      if (newv.length) {

        if (this.selectedVideo) {
          this.selectedVideo = { value: this.selectedVideo, name: newv.find((a) => a.value == this.selectedVideo.value).name }
        }
      }
    },
    mappedRecipes(newv, oldv) {
      if (newv.length) {
        if (this.selectedRecipe) {
          this.selectedRecipe = { value: this.selectedRecipe, name: newv.find((a) => a.value == this.selectedRecipe.value).name }
        }
      }
    },
    selectedVideo(newv, oldv) {

      if (newv) {
        this.itemForm.videoId = newv.value
      }
    },
    selectedRecipe(newv, oldv) {

      if (newv) {
        this.itemForm.recipeId = newv.value
      }
    }
  },
  data() {
    return {
      //export type wellnessInstructionFrequencies = '' | 'weekly' | 'everyotherday' | '' ;
      guestId: this.$route.params.id,
      isBusy: false,
      advisors: [],
      mappedVideos: [],
      mappedRecipes: [],
      mappedStays: [],
      selectedVideo: null,
      selectedRecipe: null,
      types: [
        { _id: 'drnote', name: 'Dr\'s Note' },
        { _id: 'video', name: 'Video' },
        { _id: 'nutrition', name: 'Nutrition' }],
      frequencies: [
        { _id: 'daily', name: 'Daily' },
        { _id: 'twiceaweek', name: 'Twice a week' },
        { _id: 'everyotherday', name: 'Every Other Day' },
        { _id: 'weekly', name: 'Once a week' }],
      itemForm: {
        type: null,
        advisorId: null,
        frequency: null,
        starting: null,
        ending: null,
        notes: null,
        videoId: null,
        recipeId: null,
        stayId: null
      },
    };
  },

 async created() {
  this.isBusy = true;
   await this.getInitData();
    if (this.isUpdate()) {
     await this.getData();
    }
    this.isBusy = false;
  },

  methods: {
    generateBreadCrumb() {
      return [{ label: "media" }];
    },
    isUpdate: function () {
      return this.$route.params.wellnessPlanId != null;
    },

    getInitData: async function () {
     await this.getAdvisors();
     await this.getVideos();
     await this.getRecipes();
     await this.getStays();
    },

    getAdvisors: async function () {
      let advisorResult = await PersonnelRepo.getAdvisors(
        this.userStore.state.token
      );
      this.advisors = [{ name: null, _id: null }, ...advisorResult.data.data];
    },
    getVideos: async function () {
      let results = await VideosRepo.getList(this.userStore.state.token);
      this.mappedVideos = results.data.data.map((e) => {
        return { name: e.name.en, value: e._id };
      });
    },
    getRecipes: async function () {
      let results = await RecipesRepo.getList(this.userStore.state.token);
      this.mappedRecipes = results.data.data.map((e) => {
        return { name: e.name.en, value: e._id };
      });
    },

    getStays: async function () {
      let results = await stayRepo.getStaysByGuestIdShortlist(this.guestId, this.userStore.state.token);
      this.mappedStays = results.data.data.length ? results.data.data.map(e => {
        return {
          name: `${dateformatterWithTime(e.checkin)}|${dateformatterWithTime(e.checkout)}`,
          _id: e._id
        }
      }) : [];
   
    },

    getData: async function () {
      try {

        this.isBusy = true;
        let result = await WellnessPlanResultRepo.getById(
          this.$route.params.wellnessPlanId,
          this.userStore.state.token
        );

        if (result.data.data) {
          this.itemForm.type = result.data.data.type;
          this.itemForm.frequency = result.data.data.frequency;
          this.itemForm.advisorId = result.data.data.advisorId;
          this.itemForm.starting = dateformatter(result.data.data.starting);
          this.itemForm.ending = dateformatter(result.data.data.ending);
          this.itemForm.notes = result.data.data.notes;
          this.itemForm.videoId = result.data.data.videoId;
          this.itemForm.recipeId = result.data.data.recipeId;
          this.itemForm.stayId = result.data.data.stayId;
          this.selectedVideo = { value: this.itemForm.videoId, name: this.mappedVideos.find((a) => a.value == this.itemForm.videoId)?.name }
          this.selectedRecipe = { value: this.itemForm.recipeId, name: this.mappedRecipes.find((a) => a.value == this.itemForm.recipeId)?.name }
        }
        this.$forceUpdate();
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        const objectForSubmission = {
          ...this.itemForm,
        };

        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            await WellnessPlanResultRepo.create(
              this.$route.params.id,
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({
              path: `/guests/${this.$route.params.id}/wellnessplan`,
            });
            //path: '/guests/:id/stays',
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await WellnessPlanResultRepo.update(
              this.$route.params.wellnessPlanId,
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
