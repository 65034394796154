<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb
              :crumbs="generateBreadCrumb()"
            ></route-bread-crumb>
          </nav>
        </b-col>

      </b-row>
    </base-header>

    <b-container fluid class="mt-6" style="width: max-content; min-width: 100%">
      <b-overlay :show="isBusy">
        <b-card no-body>
          <b-card-header class="border-0">
            <b-row>
              <b-col> </b-col>
              <b-col cols="3">
                <form>
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <i class="fas fa-search" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="search"
                        placeholder="Search"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </form>
              </b-col>
            </b-row>
          </b-card-header>
          <b-table
            striped
            class="w-100 text-capitalize"
            header-row-class-name="thead-light"
            :busy.sync="isBusy"
            :filter="search"
            :per-page="perPage"
            :currentPage="currentPage"
            :items="provider"
            :fields="fields"
          >
            <template #cell(guest)="row">
              <router-link
                :to="`/guests/${row.item.guest._id}/profile`"
                class="p-2"
                ><i class="mr-1 fa-lg fas fa-user"></i>
                {{ row.item.guest.fullname.en }}
              </router-link>
            </template>

            <template #cell(media_id)="row">
              <b-img
                style="max-height: 100px; width: 100px"
                thumbnail
                fluid
                :src="getImageById(row.item.media_id, 'thumbnails')"
                alt="Image 1"
              ></b-img>
            </template>
            <template #cell(view)="row">
            <router-link 
            v-if='row.item.survey'
            :to="
              `/guests/${ row.item.guest._id}/surveys/` + row.item.survey._id + ''
            " class="p-2"><i class="fas fa-poll"></i></router-link>
          <p v-else>N/A</p>
          <span   v-if='row.item.survey'>  ({{ row.item.survey? row.item.survey.status : '' }})</span>
          </template>

            <template #cell(actions)="row">
              <router-link
                :to="{
                  name: 'GuestStayEdit',
                  params: { id: row.item.guest._id, stayId: row.item._id },
                }"
                class="p-2"
                ><i class="fa-lg fas fa-arrow-right"></i
              ></router-link>
            </template>
            <template #cell(hidden)="row">
              <span size="" class="badge badge-dot mr-4">
                <i v-if="row.item.hidden" class="bg-warning"></i>
                <i v-else class="bg-success"></i>
                <span class="status">{{
                  row.item.hidden ? "Hidden" : "Visible"
                }}</span>
              </span>
            </template>
          </b-table>
          <b-card-footer class="py-4 d-flex justify-content-end">
            <base-pagination
              @change="reloadRows"
              v-model="currentPage"
              :per-page="perPage"
              :total="total"
            ></base-pagination>
          </b-card-footer>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
  <script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as stayRepo from "@/api-repo/stays";
import { dateformatter } from "@/util/dateformatter";
import BaseHeaderTransparent from "@/components/Base/BaseHeaderTransparent.vue";

export default {
  name: "staysList",
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
  },
  data() {
    return {
      guestId: this.$route.params.id,
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      total: 1,
      rows: [],
      search: null,
      fields: [
        { key: "guest", label: "Guest" },
        { key: "checkin", label: "Checkin", formatter: dateformatter },
        { key: "checkout", label: "Checkout", formatter: dateformatter },
        { key: "advisor.fullname.en", label: "Advisor" },
        { key: "retreat.name.en", label: "Retreat" },
        { key: "view", label: "Survey" },
        { key: "sessioncountFiltered", label: "# of sessions" },
        { key: "last_updated_by", label: "By" },

        { key: "actions", label: "" },
      ],
    };
  },
  methods: {
    generateBreadCrumb() {
            return [{label: "Stays"}];

    },
    reloadRows: function (e) {
      this.currentPage = e;
    },

    mapItems: function (data) {
      data.forEach(row => {
      row.sessioncountFiltered = row.stayservices ? row.stayservices.filter(e => !e.deleted).length : 0;
      })

      return data;
    },

    provider: async function (ctx) {
      this.isBusy = true;

      let result = await stayRepo.getStays(
        ctx.filter || "",
        ctx.currentPage,
        ctx.perPage,
        this.userStore.state.token
      );
      this.isBusy = false;

      this.currentPage = result.data.data[0].page + 1;
      this.total =
        result.data.data[0].total.length && result.data.data[0].total[0].count;
      return this.mapItems(result.data.data[0].rows);
    },
  },
};
</script>
  