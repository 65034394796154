

<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt-6" style="width: max-content; min-width: 100%">
      
      
        <div>
          <b-card style="margin-bottom:-10px">
            <h1 class="mb-3">{{fullname || '...'}}</h1>
            

            <div>
              <b-nav tabs >
                <b-nav-item :to="`/guests/${id}/profile`"><i class="fas fa-user mr-2" />Profile</b-nav-item>
                <b-nav-item :to="`/guests/${id}/stays`"><i class="fas fa-bed mr-2" />Stays</b-nav-item>
                <b-nav-item :to="`/guests/${id}/wellnessplan`"><i class="fas fa-heart mr-2" />Wellness Plan</b-nav-item>
                <b-nav-item :to="`/guests/${id}/healthstatus`"><i class="fas fa-medkit mr-2" />Health Conditions</b-nav-item>
                <b-nav-item :to="`/guests/${id}/medicalresults`"><i class="fas fa-flask mr-2" />Test Results</b-nav-item>
                <b-nav-item :to="`/guests/${id}/medias`"><i class="fas fa-file mr-2" />Files</b-nav-item>
                <b-nav-item :to="`/guests/${id}/surveys`"><i class="fas fa-poll mr-2" />Surveys</b-nav-item>
              </b-nav>
            </div>
          </b-card>
          <div >  <!--:class="checkClass()" -->
            <router-view></router-view>
          </div>
   
        </div>
 
    </b-container>
  </div>
</template>
  <script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import Tabs from "@/components/Tabs/Tabs";
import * as guestRepo from "@/api-repo/guests";
  import * as routeNames from "@/routes/route-names"

import { handleError, successMessage } from "@/util/error-handler";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    Tabs
  },
  props: ["type"],
  
  data() {
    return {
      lastPathString: null,
      isBusy: false,
      fullname: null,
      title: null,
      id: this.$route.params.id,
    };
  },

  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },

  mounted(){
 
  },

  beforeDestroy() {
    this.$root.$off('update:guest', () => {
    })
  },

  methods: {
generateBreadCrumb() {
        return [{label: "Guests", route: routeNames.routeGuests},{label: this.fullname}];
      },

    checkClass(){
      return (this.lastPathString === 'profile' 
      || this.lastPathString === 'edit' 
      || this.lastPathString === 'new' 
      || this.lastPathString === 'stayEdit') ? 'tab-container': null
    },
    getInitData: async function () {

      const pathArr = this.$route.fullPath.split('/');
      this.lastPathString = pathArr[pathArr.length - 1];
    },

    getData: async function () {
      try {
        this.isBusy = true;
         let result = await guestRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
      this.fullname = result.data.data.fullname.en;
      this.title = result.data.data.title.title;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    isUpdate: function () {
      return this.$route.params.id != null;
    },
  },
};
</script>
  

 





 