<template>
  <b-card no-body>
    <b-row>
      <b-col lg="8">
        <b-overlay :show="isPlansBusy">
          <b-row>
            <b-col>
              <b-card class="shadow-none" no-body>
                <b-card-header class="border-0">
                  <b-row>
                    <b-col><base-button @click="newItem()" size="sm" type="primary"><i class="mr-1 fas fa-plus" />Add
                        New</base-button>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-table v-if="renderComponent" class="w-100 text-capitalize" header-row-class-name="thead-light"
                  :busy.sync="isPlansBusy" :per-page="perPage" :currentPage="currentPage" :items="provider"
                  :fields="fields">

                  <template #cell(content)="row">
                    {{ row.item.type == 'video' ? row.item.videos.name.en : row.item.type == 'recipe' ?
                      row.item.recipes.name.en : row.item.notes }}
                  </template>
                  <template #cell(actions)="row">
                    <div class="text-right">
                      <router-link :to="`/guests/${guestId}/wellnessplan/` + row.item._id" class="p-2"><i
                          class="fa-lg fas fa-arrow-right"></i></router-link>

                      <delete-item-button style="display: inline; margin-left: 15px" @delete="
                        () => {
                          handleDelete(row.item._id);
                        }
                      "></delete-item-button>
                    </div>
                  </template>

                  <template #cell(relatedStay)="row">
                    <router-link 
                    v-if="row.item.stays"
                    :to="`/guests/${guestId}/stays/${row.item.stays._id}`"><i
                        class='fas fa-bed '></i></router-link>

                      <p v-else>N/A</p>
                      <span  v-if="row.item.stays"> {{  dateformatterWithTime(row.item.stays.checkin)}}|{{ dateformatterWithTime(row.item.stays.checkout) }}</span>

                  </template>


                </b-table>
                <b-card-footer class="py-4 d-flex justify-content-end">
                  <base-pagination @change="reloadRows" v-model="currentPage" :per-page="perPage"
                    :total="total"></base-pagination>
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
      <b-col lg="4">
        <b-overlay :show="isGoalsBusy">
          <b-card class="shadow-none" no-body>
            <b-card-header class="border-0">
              <h4>Fitness Goal </h4>
              <validation-observer v-slot="{ handleSubmit }" ref="goalFormValidator">
                <b-form class="needs-validation" @submit.prevent="handleSubmit(goalFormSubmit)" novalidate>
                  <div>
                    <b-row>
                      <b-col lg="12">
                        <base-input type="select" :required="true" label="Advisor" :name="'Advisor'"
                          placeholder="Select advisor" v-model="goalForm.advisorId" :options="advisors" :key-field="'_id'"
                          :value-field="'_id'" :text-field="'name'"></base-input>
                      </b-col>
                      <b-col lg="12">
                        <base-input type="text" :name="'Recommended Weight'" label="Recommended Weight" :required="true"
                          placeholder="Recommended Weight" v-model="goalForm.recommended_weight">
                        </base-input>
                      </b-col>
                      <b-col lg="12">
                        <base-input type="text" :name="'Recommended Calories'" label="Recommended Calories"
                          :required="true" placeholder="Recommended Calories" v-model="goalForm.recommended_calories">
                        </base-input>
                      </b-col>
                      <b-col lg="12">
                        <base-input type="text" :name="'Recommended Steps'" label="Recommended Steps" :required="true"
                          placeholder="Recommended Steps" v-model="goalForm.recommended_steps">
                        </base-input>
                      </b-col>
                      <b-col lg="12">
                        <base-input type="text" :name="'Daily Active Minutes'" label="Daily Active Minutes"
                          :required="true" placeholder="Daily Active Minutes"
                          v-model="goalForm.recommended_active_minutes">
                        </base-input>
                      </b-col>
                    </b-row>
                  </div>
                  <base-button type="primary" native-type="submit" class="my-4">
                    Update
                  </base-button>
                </b-form>
              </validation-observer>
            </b-card-header>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as Repo from "@/api-repo/medicalresults";
import { fullname } from "@/util/fullname";
import { capitalize } from "@/util/capitalize";
import FileInput from "@/components/Inputs/FileInput.vue";
import { getSourceFromFile } from "@/util/imageSource";
import { dateformatter, dateformatterWithTime } from "@/util/dateformatter";
import { handleError, successMessage } from "@/util/error-handler";
import DeleteItemButton from "@/components/Base/DeleteItemButton.vue";
import * as PersonnelRepo from "@/api-repo/personnel";
import * as WellnessGoalRepo from "@/api-repo/wellnessgoals";
import * as WellnessPlansRepo from "@/api-repo/wellnessplans";

export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    FileInput,
    DeleteItemButton,
  },
  created() {
    this.getInitData();
    this.getGoalsData();

    //this.getData();
  },
  data() {
    return {
      renderComponent: true,
      guestId: this.$route.params.id,
      dateformatterWithTime:dateformatterWithTime,
      showList: false,
      isGoalsBusy: false,
      isPlansBusy: false,
      currentPage: 1,
      perPage: 20,
      total: 1,
      plans: [],
      advisors: [],
      goalForm: {
        recommended_calories: 0,
        recommended_steps: 0,
        recommended_weight: 0,
        recommended_active_minutes: 0,
        advisorId: null,
      },
      fields: [
        { key: "type", label: "Type" },
        { key: "content", label: "Title" },
        { key: "last_updated", label: "By", formatter: dateformatter },
        { key: "relatedStay", label: "Related Stay" },
        { key: "actions", label: "" },
      ],
    };
  },
  computed: {},
  methods: {
    generateBreadCrumb() {
      return [{ label: "media" }];
    },
    getSourceFromFile,
    fullname,
    capitalize,

    reloadRows: function (e) {
      this.currentPage = e;
    },
    newItem: function () {
      this.$router.push({
        path: `/guests/${this.$route.params.id}/wellnessplan/new`,
      });
    },
    getInitData: async function () {
      let advisorResult = await PersonnelRepo.getAdvisors(
        this.userStore.state.token
      );
      this.advisors = advisorResult.data.data;
    },

    getGoalsData: async function () {
      this.isGoalsBusy = true;
      let result = await WellnessGoalRepo.getGoalsByGuest(
        this.$route.params.id,
        this.userStore.state.token
      );

      this.goalForm.recommended_calories =
        result.data.data?.recommended_calories || 0;
      this.goalForm.recommended_active_minutes =
        result.data.data?.recommended_active_minutes || 0;
      this.goalForm.recommended_weight = result.data.data?.recommended_weight || 0;
      this.goalForm.recommended_steps = result.data.data?.recommended_steps || 0;
      this.goalForm.advisorId = result.data.data?.advisorId || null;
      this.isGoalsBusy = false;
    },


    handleDelete: async function (id) {
      try {
        await Repo.deleteR(id, this.userStore.state.token);
        successMessage("Item deleted successfully");
        this.forceRerender();
      } catch (e) {
        handleError(e);
      }
    },
    goalFormSubmit: async function () {
      const isValid = await this.$refs.goalFormValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;
        let submissionForm = {
          ...this.goalForm,
        };
        try {
          this.isGoalsBusy = true;
          await WellnessGoalRepo.updateGoalsByGuest(
            this.$route.params.id,
            submissionForm,
            this.userStore.state.token
          );
          successMessage("Item updated successfully");
          //path: '/guests/:id/stays',
        } catch (e) {
          handleError(e);
        } finally {
          this.isGoalsBusy = false;
        }
      }
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    provider: async function (ctx) {




      this.isPlansBusy = true;
      let result = await WellnessPlansRepo.getByType(
        this.$route.params.id,
        null,
        ctx.currentPage,
        ctx.perPage,
        this.userStore.state.token
      );
      this.isPlansBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      this.total =
        result.data.data[0].total.length && result.data.data[0].total[0].count;
      return result.data.data[0].rows;
    },
  },
};
</script>
  