<template>
  <div>

    <b-overlay :show="isBusy">
      <b-card class="pt-0">



        <b-card-header class="pt-0 pl-0 border-0">
          <a role="button" @click="$router.go(-1)" class="small pointer">
            <i class="mr-1 fas fa-angle-left" />Test Results
          </a>
        </b-card-header>



        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <b-form class="needs-validation" @submit.prevent="handleSubmit(itemFormSubmit)" novalidate>


            <div>
              
              <b-row>
                <b-col lg="12">
                  <media-gallery-input name="Gallery" :guestId="$route.params.id" label="Gallery" :format="''"
                    :required="true" v-model="itemForm.media_ids">
                  </media-gallery-input>
                </b-col>

              </b-row>
              <b-row>
                  <b-col lg="6">
                    <base-input type="select" :required="true" label="Stay" :name="'Stay'" placeholder="Select Stay"
                      v-model="itemForm.stayId" :options="mappedStays" :key-field="'_id'" :value-field="'_id'"
                      :text-field="'name'"></base-input>
                  </b-col>
                </b-row>

                <b-row> 
                <b-col lg="6">
                  <base-input type="select" :required="true" label="Advisor" :name="'Advisor'"
                    placeholder="Select advisor" v-model="itemForm.advisorId" :options="advisors" :key-field="'_id'"
                    :value-field="'_id'" :text-field="'name'"></base-input>
                </b-col>

                <b-col lg="6">
                  <base-datetime-input :required="true" name="Test Date" label="Test Date" v-model="itemForm.testDate">
                  </base-datetime-input>

                </b-col>
              </b-row>

          


              <b-row>
                <b-col lg="6">
                  <base-input type="text" :name="'Title - English'" label="Title - English" :required="true"
                    placeholder="Title - English" v-model="itemForm.title.en">
                  </base-input>
                </b-col>
                <b-col lg="6">
                  <base-input type="text" :name="'Title - Arabic'" label="Title - Arabic" :required="true"
                    placeholder="Title - Arabic" v-model="itemForm.title.ar">
                  </base-input>
                </b-col>
              </b-row>

              <b-row>


                <b-col lg="6">
                  <base-input :name="'Notes'" label="Notes" :rows="'5'" placeholder="Notes" :type="'textarea'"
                    v-model="itemForm.notes">
                  </base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="6">
                  <b-form-group>
                    <label class="form-control-label">Visible</label>
                    <b-form-checkbox class="mb-3" v-model="itemForm.visible">
                      Hide from mobile app
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <base-button type="primary" native-type="submit" class="my-4">
              {{ isUpdate() ? "Update" : "Create" }}
            </base-button>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>

  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import { dateformatter, dateformatterWithTime  } from "@/util/dateformatter";

import * as MedicalResultRepo from "@/api-repo/medicalresults";
import * as PersonnelRepo from "@/api-repo/personnel";
import * as StayRepo from "@/api-repo/stays";

import BaseMultiSelect from "@/components/Inputs/BaseMultiSelect.vue";

import MediaGalleryInput from "@/components/Inputs/MediaGalleryInput.vue";
import { handleError, successMessage } from "@/util/error-handler";

export default {
  name: "stay-edit",
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    MediaGalleryInput,
    BaseMultiSelect,
    baseDatetimeInput
  },
  props: ["type"],
  data() {
    return {
      isBusy: false,
      guestId: this.$route.params.id,
      advisors: [],
      mappedStays: [],
      itemForm: {
        title: { en: null, ar: null },
        media_ids: [],
        healthstatusId: null,
        advisorId: null,
        testDate: null,
        notes: null,
        visible: null,
        stayId: null
      },
    };
  },

  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },

  methods: {
    generateBreadCrumb() {
      return [{ label: "media" }];
    },
    isUpdate: function () {
      return this.$route.params.medicalresultId != null;
    },

    getInitData: async function () {
      await this.getStays();
      let advisorResult = await PersonnelRepo.getAdvisors(
        this.userStore.state.token
      );
      this.advisors = [{ name: null, _id: null }, ...advisorResult.data.data];
    },
    getData: async function () {
      try {
        let advisorResult = await PersonnelRepo.getAdvisors(
          this.userStore.state.token
        );

        this.advisors = [{ name: null, _id: null }, ...advisorResult.data.data];

        this.isBusy = true;
        let result = await MedicalResultRepo.getById(
          this.$route.params.medicalresultId,
          this.userStore.state.token
        );
        if (result.data.data) {
          Object.keys(this.itemForm).forEach((field) => {
            if (field === 'title') {
              this.itemForm.title = result.data.data.title || { en: null, ar: null }
            } else if (field == "testDate") {
              this.itemForm.testDate = dateformatter(result.data.data[field])
            } else {
              this.itemForm[field] = result.data.data[field];
            }
          });
        }
        console.log(this.itemForm)
        this.$forceUpdate();
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },


    getStays: async function () {
      let results = await StayRepo.getStaysByGuestIdShortlist(this.guestId, this.userStore.state.token);
      this.mappedStays = results.data.data.length ? results.data.data.map(e => {
        return {
          name: `${dateformatterWithTime(e.checkin)}|${dateformatterWithTime(e.checkout)}`,
          _id: e._id
        }
      }) : [];
    },

    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        const objectForSubmission = {
          ...this.itemForm,
          advisor: this.itemForm.advisorId
            ? this.advisors.find((e) => e._id === this.itemForm.advisorId)?.name
            : null,
        };

        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            await MedicalResultRepo.create(
              this.$route.params.id,
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({
              path: `/guests/${this.$route.params.id}/medicalresults`,
            });
            //path: '/guests/:id/stays',
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await MedicalResultRepo.update(
              this.$route.params.medicalresultId,
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
