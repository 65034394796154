import axios from "axios"

export const getUpcomingGuest = (guestId, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `stayservices/guest/upcoming/${guestId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getByDate = (search, page,count, datetime, token) => {
  console.log(`stayservices/byDate/?datetime=${datetime}&search=${search}&count=${count}&page=${page}`)
  return axios.get(process.env.VUE_APP_API_URL + `stayservices/byDate/?datetime=${datetime}&search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getUniqueDates = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `stayservices/uniquedates`, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}



export const checkin = (sessionId, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `stayservices/checkin/${sessionId}`, {}, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const create = (stayId, item, token) => {
  const data = {
    ...item
  }
  return axios.post(process.env.VUE_APP_API_URL + `stayservices/stay/${stayId}`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  const data = {
    ...item
  }
  return axios.put(process.env.VUE_APP_API_URL + `stayservices/` + id, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `stayservices/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getStayServicesByStayId = (stayId, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `stayservices/stay/${stayId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `stayservices/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

/*export const getByType = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `categories/type/${type}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}*/
