import axios from "axios"



export const create = (item, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `healthstatuses/`, {
    ...item
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `healthstatuses/` + id, {
    ...item
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `healthstatuses/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const get = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `healthstatuses/?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getList = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `healthstatuses/list`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `healthstatuses/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

/*export const getByType = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `categories/type/${type}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}*/
