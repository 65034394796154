<template>
  <div>
   
    


      <b-overlay :show="isBusy">
        <b-card class="pt-0">
         
              
          <b-card-header class="pt-0 pl-0 border-0">
              <a role="button" @click="$router.go(-1)" class="small pointer">
              <i class="mr-1 fas fa-angle-left"/>Stays
              </a>
          </b-card-header>

          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(itemFormSubmit)"
              novalidate
            >
             

              <div >
                <b-row>

                  
                  <b-col lg="4">
                    <base-input
                      type="select"
                      :required="true"
                      label="Advisor"
                      :name="'Advisor'"
                      placeholder="Select advisor"
                      v-model="itemForm.advisorId"
                      :options="advisors"
                      :key-field="'_id'"
                      :value-field="'_id'"
                      :text-field="'name'"
                    ></base-input>
                  </b-col>

                  <b-col lg="4">
                    <base-input
                      type="select"
                      :required="true"
                      label="Retreat"
                      :name="'Retreat'"
                      placeholder="Select retreat"
                      v-model="itemForm.retreatId"
                      :options="retreats"
                      :key-field="'_id'"
                      :value-field="'_id'"
                      :text-field="'name'"
                    ></base-input>
                  </b-col>

                  <b-col lg="4">
                    <base-input
                      type="number"
                      :name="'Guests'"
                      label="Guests"
                      :required="true"
                      placeholder="Guests"
                      v-model="itemForm.guests"
                    >
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>

                  <b-col lg="6">
                    <base-datetime-input
                    :required="true"
                    name="Check-in Date"
                    label="Check-in Date"
                    v-model="itemForm.checkin"
                    >
                    </base-datetime-input>
                  </b-col>

                  <b-col lg="6">
                    <base-datetime-input
                    :required="true"
                    name="Check-out Date"
                    label="Check-out Date"
                    v-model="itemForm.checkout"
                    >
                    </base-datetime-input>
                  </b-col>
                 
                  
                </b-row>
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update Information" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
     
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import * as StayRepo from "@/api-repo/stays";
import * as PersonnelRepo from "@/api-repo/personnel";
import * as RetreatRepo from "@/api-repo/retreats";

import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import { handleError, successMessage } from "@/util/error-handler";
import {getCountriesList, getLanguages} from '@/util/countries'

export default {
  name: "stay-edit",
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    baseDatetimeInput
  },
  props: ["type"],
  data() {
    return {
      countries: getCountriesList(),
      languages: getLanguages(),
      isBusy: false,
      advisors: [],
      retreats: [],
      newGuest: true,
      newGuestForm: {
        first_name: { en: null, ar: null },
        last_name: { en: null, ar: null },
        title: { en: null, ar: null },
        dob: null,
        nationality: null,
        communication_language: null
      },
      itemForm: {
        advisorId: null,
        retreatId: null,
        guests: null,
        checkin:null,
        checkout: null
      },
    };
  },

  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  /*

media_id: 
    title: {en: , ar: }
    text: {en: , ar: }
    target_id: 
    target_type: 
    hidden: 
    tags: []
  */
  methods: {
generateBreadCrumb() {
        return [{label: "media"}];
      },
    
    getInitData: async function () {
      let advisorResult = await PersonnelRepo.getAdvisors(
        this.userStore.state.token
      );
      let retreatResult = await RetreatRepo.getList(this.userStore.state.token);

      this.advisors = advisorResult.data.data;
      this.retreats = retreatResult.data.data;
    },

    getData: async function () {
      try {
        this.isBusy = true;
        let result = await StayRepo.getById(
          this.$route.params.stayId,
          this.userStore.state.token
        );

        this.itemForm.checkin = result.data.data.checkin;
        this.itemForm.checkout = result.data.data.checkout;
        this.itemForm.advisorId = result.data.data.advisorId;
        this.itemForm.retreatId = result.data.data.retreatId;
        this.itemForm.guests = result.data.data.guests;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    isUpdate: function () {
      return this.$route.params.stayId != null;
    },
    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;

        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            let res = await StayRepo.create( this.$route.params.id, this.itemForm, this.userStore.state.token);
            successMessage("Item created successfully");
            //path: '/guests/:id/stays',
            this.$router.replace({
              path: `/guests/${this.$route.params.id}/stays`,
            });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await StayRepo.update(
              this.$route.params.stayId,
              this.itemForm,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
