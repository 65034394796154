/* export interface  StayServiceModel {
   guestId: ObjectId,
   stayId: ObjectId,
   serviceId: ObjectId,
   status:  stayServiceStatusType
   included: boolean,
   notes: string | null,
   deleted: boolean,
   last_updated?: Date;
   last_updated_by: string;
   lastsynced? : Date;
} 
 */

<template>
  <modal 
  @close='close'
  :show="showModal">
    <b-overlay :show="isBusy">
     
      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <b-form
          class="needs-validation"
          @submit.prevent="handleSubmit(itemFormSubmit)"
          novalidate
        >
          <div class="">
            <b-row>
              <b-col lg="12">
                <base-multi-select
                  ref="services"
                  name="Service"
                  :required="true"
                  v-model="itemForm.selectedService"
                  :options="allServices"
                  :multiple="false"
                  placeholder="Select Service"
                  label="Service"
                  :trackby="'_id'"
                >
                </base-multi-select>
            
              </b-col>
              <b-col lg="6">
                   <base-datetime-input
                    :required="true"
                    name="Date"
                    label="Date"
                    v-model="itemForm.datetime"
                    >
                    </base-datetime-input>
                
              </b-col>
                 <b-col lg="6">
                   <base-time-input
                    :required="true"
                    name="Start Time"
                    label="Start Time"
                    v-model="itemForm.selectedtime"
                    >
                    </base-time-input>

                
              </b-col>
              <b-col lg="6">
                <base-input
                  type="select"
                  :required="true"
                  label="Status"
                  :name="'Status'"
                  placeholder="Select Status"
                  v-model="itemForm.status"
                  :options="statuses"
                  :key-field="'_id'"
                  :value-field="'_id'"
                  :text-field="'name'"
                ></base-input>
              </b-col>
            
              <b-col lg="6">
                <base-input
                  :name="'Notes'"
                  label="Notes"
                  :rows="'5'"
                  placeholder="Notes"
                  :type="'textarea'"
                  v-model="itemForm.notes"
                >
                </base-input>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  
                  <b-form-checkbox class="mb-3" v-model="itemForm.included">
                    Included in retreat package
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>

    <template slot="footer">
      <div>
        <base-button @click="close" type="link" class="ml-auto"
          >Cancel</base-button
        >
        <base-button @click="itemFormSubmit" type="primary">{{
          isUpdate() ? "Update" : "Create"
        }}</base-button>
      </div>
    </template>
  </modal>
</template>






<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';

import { dateformatter } from "@/util/dateformatter";
import * as StayRepo from "@/api-repo/staysessions";
import * as PersonnelRepo from "@/api-repo/personnel";
import * as TreatmentRepo from "@/api-repo/treatments";
import * as ActivityRepo from "@/api-repo/activities";
import BaseMultiSelect from "@/components/Inputs/BaseMultiSelect.vue";
import { handleError, successMessage } from "@/util/error-handler";
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import baseTimeInput from "@/components/Inputs/BaseTimeInput";

export default {
  name: "modal-guest-stay-session-edit",
  inject: ["userStore"],
  components: { BaseMultiSelect,baseDatetimeInput, baseTimeInput },
  props: ["show", "showModal", "staySessionId" , "passedDatetimeInfo"],
  data() {
    return {
      statuses: [
        { _id: "scheduled", name: "Scheduled" },
        { _id: "attended", name: "Attended" },
      ],
      isBusy: false,
      advisors: [],
      allServices: [],
      retreats: [],
      itemForm: {
        selectedService: null,
        advisorId: null,
        notes: null,
        included: null,
        status: null,
        datetime: null,
        selectedtime: null
      },
    };
  },

  async created() {
    await this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  /*

media_id: 
    title: {en: , ar: }
    text: {en: , ar: }
    target_id: 
    target_type: 
    hidden: 
    tags: []
  */
  methods: {
    refreshData() {
      this.getData();
    },

    initiateDateTime(){
      if(this.passedDatetimeInfo){
       this.itemForm.datetime = dateformatter( this.passedDatetimeInfo);
      let dateForTime =  new Date(this.passedDatetimeInfo);
        this.itemForm.selectedtime = dateForTime ? `${new Date(dateForTime).getHours()}:${new Date(dateForTime).getMinutes()}:${new Date(dateForTime).getSeconds()}`: null;
      }
      
    },
    dateformatter: dateformatter,

    getInitData: async function () {
      let treatmentRes = await TreatmentRepo.getList(
        this.userStore.state.token
      );
      let treatments = treatmentRes.data.data;

      let activityRes = await ActivityRepo.getList(this.userStore.state.token);
      let activities = activityRes.data.data;

      this.allServices = [...treatments, ...activities];
    },

    close() {
      this.reset();
      this.$emit("closeModal");
    },

    reset(){
        Object.keys(this.itemForm).forEach(key=>{
          this.itemForm[key] = null;
        })
    },

    isUpdate: function () {
      return this.staySessionId != null;
    },

    getData: async function () {
      try {
        this.isBusy = true;
        let result = await StayRepo.getById(
          this.staySessionId,
          this.userStore.state.token
        );
        this.itemForm.selectedService = result.data.data.serviceId
          ? this.allServices.find((e) => e._id === result.data.data.serviceId)
          : null;
        this.itemForm.notes = result.data.data.notes;
        this.itemForm.included = result.data.data.included;
        this.itemForm.status = result.data.data.status;
        this.itemForm.datetime = dateformatter(result.data.data.datetime);
        let dateForTime =  result.data.data.datetime ? new Date(result.data.data.datetime): null;
        this.itemForm.selectedtime = dateForTime ? `${new Date(dateForTime).getHours()}:${new Date(dateForTime).getMinutes()}:${new Date(dateForTime).getSeconds()}`: null;


      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      
      let myDatetime = new Date(dateformatter(this.itemForm.datetime) + ' ' + this.itemForm.selectedtime);
      if (isValid) {
        let itemsForSubmission = {
          ...this.itemForm,
          serviceId: this.itemForm.selectedService._id,
         datetime: myDatetime
        };
        try {
          this.isBusy = true;

          if (this.isUpdate()) {
            await StayRepo.update(
              this.staySessionId,
              itemsForSubmission,
              this.userStore.state.token
            );
          } else {
            await StayRepo.create(
              this.$route.params.stayId,
              itemsForSubmission,
              this.userStore.state.token
            );
          }
          successMessage("Item updated successfully");
          this.$emit("refresh");
          this.close();
        } catch (e) {
          handleError(e);
        } finally {
          this.isBusy = false;
        }
      }
    },
  },
};
</script>