<template>
  <div>
    <base-header v-if="!isUpdate()" class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb
              :crumbs="generateBreadCrumb()"
            ></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-card>
      <div class="row">
        <div class="col-8">
          <b-overlay :show="isBusy">
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
              <h6 class="heading-small text-muted mb-4">Basic Information</h6>
              <b-form
                class="needs-validation"
                @submit.prevent="handleSubmit(itemFormSubmit)"
                novalidate
              >
                <div class="">
                  <b-row>

                      <b-col lg="6">
                    <base-input
                    
                        type="text"
                        :name="'Email'"
                        label="Email"
                        :required="true"
                        placeholder="Email"
                        v-model="itemForm.email"
                      >
                      </base-input>
                      </b-col>
                  </b-row>
 <b-row>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'First Name - English'"
                        label="First Name - English"
                        :required="true"
                        placeholder="First Name - English"
                        v-model="itemForm.first_name.en"
                      >
                      </base-input>
                    </b-col>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'First Name - Arabic'"
                        label="First Name - Arabic"
                        :required="true"
                        placeholder="First Name - Arabic"
                        v-model="itemForm.first_name.ar"
                      >
                      </base-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'Last Name - English'"
                        label="Last Name - English"
                        :required="true"
                        placeholder="Last Name - English"
                        v-model="itemForm.last_name.en"
                      >
                      </base-input>
                    </b-col>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'Last Name - Arabic'"
                        label="Last Name - Arabic"
                        :required="true"
                        placeholder="Last Name - Arabic"
                        v-model="itemForm.last_name.ar"
                      >
                      </base-input>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'Title - English'"
                        label="Title - English"
                        :required="true"
                        placeholder="Title - English"
                        v-model="itemForm.title.en"
                      >
                      </base-input>
                    </b-col>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'Title - Arabic'"
                        label="Title - Arabic"
                        :required="true"
                        placeholder="Title - Arabic"
                        v-model="itemForm.title.ar"
                      >
                      </base-input>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col lg="6">
                      <base-datetime-input
                        :required="false"
                        name="DOB"
                        label="DOB"
                        v-model="itemForm.dob"
                        >
                    </base-datetime-input>
                     
                    </b-col>

                    <b-col lg="6">
                      <base-input
                        type="select"
                        :required="true"
                        label="Nationality"
                        :name="'Nationality'"
                        placeholder="Select nationality"
                        v-model="itemForm.nationality"
                        :options="countries"
                        :key-field="'code'"
                        :value-field="'code'"
                        :text-field="'name'"
                      ></base-input>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col lg="6">
                      <base-input
                        type="select"
                        :required="true"
                        label="Communication language"
                        :name="'language'"
                        placeholder="Select language"
                        v-model="itemForm.communication_language"
                        :options="languages"
                        :key-field="'code'"
                        :value-field="'code'"
                        :text-field="'name'"
                      ></base-input>
                    </b-col>
                  </b-row>
                </div>

                <base-button type="primary" native-type="submit" class="my-4">
                  {{ isUpdate() ? "Update Information" : "Create" }}
                </base-button>
              </b-form>
            </validation-observer>
          </b-overlay>
        </div>

        <div class="col-4" v-if="isUpdate()">
          <b-overlay :show="isBusy">
            <validation-observer
              v-slot="{ handleSubmit }"
              ref="formValidatorPass"
            >
              <b-form
                class="needs-validation"
                @submit.prevent="handleSubmit(submitPassword)"
                novalidate
              >
                <h6 class="heading-small text-muted mb-4">App Credentials</h6>

                <b-row>
                  <b-col lg="12">
                    <base-input
                      type="password"
                      :name="'password'"
                      label="Password"
                      :required="true"
                      placeholder="Type password"
                      v-model="itemForm2.password"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="12">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                    >
                      Update Password
                    </base-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <br />
          </b-overlay>

          <h6 class="heading-small text-muted mb-4">CARD</h6>
          <div class="d-flex justify-content-center">
            <div class="p-4" >
          <qrcode-vue id="card-wrapper" :value="$route.params.id" size="200" level="H" />
          </div>
          </div>
          <base-button
            @click="printCard()"
                      type="primary"
                      
                      class="my-4"
                    >
                      Print                    </base-button>

        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import Vue from 'vue'
import QrcodeVue from 'qrcode.vue'
import * as GuestRepo from "@/api-repo/guests";
import {dateformatter} from "@/util/dateformatter";
import { handleError, successMessage } from "@/util/error-handler";
import { getCountriesList, getLanguages } from "@/util/countries";

export default {
  name: "guest-edit",
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    baseDatetimeInput,
    QrcodeVue
  },
  props: ["type"],
  data() {
    return {
      countries: getCountriesList(),
      languages: getLanguages(),
      isBusy: false,
      dates: {
        simple: new Date(),
        range: "2019-04-19 to 2019-04-24",
      },
      itemForm: {
        first_name: { en: null, ar: null },
        last_name: { en: null, ar: null },
        title: { en: null, ar: null },
        dob: null,
        nationality: null,
        communication_language: null,
        email: null,
      },
      itemForm2: {
        password: null
      }
    };
  },

  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  /*

media_id: 
    title: {en: , ar: }
    text: {en: , ar: }
    target_id: 
    target_type: 
    hidden: 
    tags: []
  */
  methods: {
    printCard() {
       let mywindow = window.open('', 'PRINT', 'height=400,width=600');
      let dataUrl = document.getElementById('card-wrapper').firstChild.toDataURL();
        mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write('<img src="' + dataUrl + '">');
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

    return true;

  
    },
    generateBreadCrumb() {
      return [{ label: "media" }];
    },
    getInitData: async function () {},

    getData: async function () {
      try {
        this.isBusy = true;
        let result = await GuestRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );

        this.itemForm.first_name = result.data.data.first_name;
        this.itemForm.last_name = result.data.data.last_name;
        this.itemForm.title = result.data.data.title;
        this.itemForm.dob = dateformatter(result.data.data.dob);
        this.itemForm.nationality = result.data.data.nationality;
        this.itemForm.email = result.data.data.email;
        this.itemForm.communication_language =
          result.data.data.communication_language;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    isUpdate: function () {
      return this.$route.params.id != null;
    },

    submitPassword: async function () {
      try {
        const isValid = await this.$refs.formValidatorPass.validate();

        if (!isValid) {
          return;
        }
        this.isBusy = true;
        let res = await GuestRepo.updatePassword(
          this.$route.params.id,
          this.itemForm2.password,
          this.userStore.state.token
        );
        successMessage("Item created successfully");
      } catch (e) {
        console.log(e.message)
        handleError('Password must be at least 8 characters');
      } finally {
        this.isBusy = false;
      }
    },

    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;
        const objectForSubmission = {
          ...this.itemForm,
        };
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            let res = await GuestRepo.create(
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({
              path: "/guests/" + res.data.data.insertedIds["0"],
            });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await GuestRepo.update(
              this.$route.params.id,
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
