<template>
  <div>
 
      <b-overlay :show="isBusy">
         <b-card class="pt-0">
         

          
          <b-card-header class="pt-0 pl-0 border-0">
              <a role="button" @click="$router.go(-1)" class="small pointer">
                <i class="mr-1 fas fa-angle-left"/>Files
              </a>
          </b-card-header>

          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <div v-if="!isUpdate()">
              <h6 class="heading-small text-muted mb-4">Media Information</h6>

              <file-input
                rules="required"
                :label="'Image'"
                :name="'imageFile'"
                :required="true"
                v-on:change="fileInputChanged"
              ></file-input>
            </div>
            <div v-else>
              <b-row>
                <b-col lg="12" class="d-flex justify-content-center">
                  <b-img
                    v-if="mediaForm.file"
                    style="max-height: 400px"
                    thumbnail
                    class="mb-4 align-self-center"
                    :src="getSourceFromFile(mediaForm.file.filename, 'medium')"
                    alt="Image 1"
                  ></b-img>
                </b-col>
              </b-row>
            </div>
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(mediaFormSubmit)"
              novalidate
            >
              <div class="">
                <b-row>
                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Name'"
                      label="Name"
                      :required="true"
                      placeholder="Name"
                      v-model="mediaForm.name"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input
                      type="select"
                      :required="true"
                      label="Format"
                      :name="'Format'"
                      placeholder="Format"
                      v-model="mediaForm.format"
                      :options="formats"
                      :key-field="'value'"
                      :value-field="'value'"
                      :text-field="'name'"
                    ></base-input>
                  </b-col>
                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Tags</label>
                      <b-form-tags
                        input-id="tags-basic"
                        v-model="mediaForm.tags"
                      ></b-form-tags>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6">
                    <label class="form-control-label">&nbsp;</label>
                    <b-form-checkbox class="mb-3" v-model="mediaForm.visible">
                      Visible
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>

  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { capitalize } from "@/util/capitalize";
import * as mediaRepo from "@/api-repo/media";
import FileInput from "@/components/Inputs/FileInput.vue";
import { getSourceFromFile } from "@/util/imageSource";
//import { ValidationProvider } from "vee-validate";
import { handleError, successMessage } from "@/util/error-handler";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    FileInput,
    // ValidationProvider,
  },
  data() {
    return {
      isBusy: false,
      file: null,
      formats: [
        { name: "Document", value: "document" },
        { name: "Image", value: "image" },
      ],
      mediaForm: {
        format: null,
        file: null,
        name: null,
        tags: [],
        _id: null,
        folder: null,
        visible: false,
      },
    };
  },
  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  methods: {
generateBreadCrumb() {
        return [{label: "media"}];
      },
    capitalize,
    getSourceFromFile,
    getInitData: async function () {},
    fileInputChanged: function (e) {
      this.file = e[0];
    },
    getData: async function () {
      try {
        this.isBusy = true;
        let result = await mediaRepo.getById(
          this.$route.params.mediaId,
          this.userStore.state.token
        );
        this.mediaForm.name = result.data.data.name;
        this.mediaForm.file = result.data.data.file;
        this.mediaForm.visible = result.data.data.visible || false;
        this.mediaForm.tags = result.data.data.tags;
        this.mediaForm.format = result.data.data.format;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },
    isUpdate: function () {
      return this.$route.params.mediaId != null;
    },
    mediaFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            await mediaRepo.uploadToGuest(
              this.file,
              this.file.name,
              {
                format: this.mediaForm.format,
                name: this.mediaForm.name,
                tags: this.mediaForm.tags,
                visible: this.mediaForm.visible || false,
              },
              this.userStore.state.token,
              this.$route.params.id
            );
            successMessage("Media created successfully");
            this.$router.replace({
              path: `/guests/${this.$route.params.id}/medias`,
            });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await mediaRepo.update(
              this.$route.params.mediaId,
              {
                format: this.mediaForm.format,
                guestId: this.$route.params.id,
                name: this.mediaForm.name,
                tags: this.mediaForm.tags,
                visible: this.mediaForm.visible || false,
              },
              this.userStore.state.token
            );
            successMessage("User updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
