 

<template>
 <div>

<b-overlay :show="isBusy" v-if="survey">
 <b-card class="pt-0">
  
    <b-card-header class="pt-0 pl-0 border-0">
        <a role="button" @click="$router.go(-1)" class="small pointer">
        <i class="mr-1 fas fa-angle-left"/>Surveys
        </a>
    </b-card-header>

  <div v-if="survey">
    <b-card  >
      <b-row>
        <b-col sm="12">
          <h2>Personal Information</h2><br/>
        </b-col>
      </b-row>
       <b-row>
        <b-col md="4">
          <p><h3>Title:</h3> {{ survey.title }}</p>
          <p><h3>First Name:</h3> {{ survey.firstName }}</p>
          <p><h3>Last Name:</h3> {{ survey.lastName }}</p>
          <p><h3>Gender:</h3> {{ survey.gender }}</p>
          <p><h3>Date of Birth:</h3> {{ survey.dob }}</p>
          <p><h3>Age:</h3> {{ survey.age }}</p>
        </b-col>
        <b-col md="4">
          
          
          <p><h3>Nationality:</h3> {{ survey.nationality }}</p>
          <p><h3>Occupation:</h3> {{ survey.occupation }}</p>
          <p><h3>Country of Residence:</h3> {{ survey.countryOfResidence }}</p>
          <p><h3>Ethnic Background:</h3> {{ survey.ethnicBackground }}</p>
          
          <p><h3>Blood Type:</h3> {{ survey.bloodType }}</p>
        </b-col>
<b-col md="4">
          <p><h3>Contact Number:</h3> {{ survey.contactNumber }}</p>
          <p><h3>Email:</h3> {{ survey.email }}</p>
         </b-col>

       </b-row>
       
       <b-row>
         
      
        
        <b-col md="8">

          <b-row>
            <b-col md="12">
              <br/><h2>Current Health Status</h2><br/>
            </b-col>
            <b-col md="6">
              <h3>Has Health Conditions: {{ survey.currentHealthStatus.hasSpecificHealthCondition ? 'Yes' : 'No' }}</h3> 
              <p v-if="survey.currentHealthStatus.hasSpecificHealthCondition">
                <div v-for="(item, i) in survey.currentHealthStatus.specificHealthCondition">{{ item }}</div>
              </p>

              <h3>Is Pregnant: {{ survey.currentHealthStatus.isPregnantOrPostPartum ? 'Yes' : 'No' }}</h3> 
              <p v-if="survey.currentHealthStatus.isPregnantOrPostPartum">
                <div >{{ survey.currentHealthStatus.weeksPregnant }}</div>
              </p>

              <h3>Allergies</h3> 
              <p>
                <div v-for="(item, i) in survey.currentHealthStatus.allergicToSubstances">{{ item.substance }} - {{ item.reaction }}</div>
              </p>


              
            </b-col>
            <b-col md="6">
              <h3>Has Disabilities: {{ survey.currentHealthStatus.hasDisabilities ? 'Yes' : 'No' }}</h3> 
              <p v-if="survey.currentHealthStatus.hasDisabilities">
                <div v-for="(item, i) in survey.currentHealthStatus.disabilities">{{ item }}</div>
              </p>
              <h3>Recent Surgeries: {{ survey.currentHealthStatus.hasRecentSurgeryOrCosmeticProcedure ? 'Yes' : 'No' }}</h3> 
               <p v-if="survey.currentHealthStatus.hasRecentSurgeryOrCosmeticProcedure">
                <div v-for="(item, i) in survey.currentHealthStatus.recentSurgeryOrCosmeticProcedure">{{ item }}</div>
              </p>
              <h3>Medications: {{ survey.currentHealthStatus.isTakingMedicationsOrSupplements ? 'Yes' : 'No' }}</h3> 
               <p v-if="survey.currentHealthStatus.isTakingMedicationsOrSupplements">
                <div v-for="(item, i) in survey.currentHealthStatus.medicationsOrSupplements">{{ item }}</div>
              </p>
              
            </b-col>
          </b-row>
          
         
        </b-col>

        <b-col md="4">
          <br/><h2>Purpose of Visit</h2><br/>
          <b-table thead-class="d-none"  :items="survey.purposeOfVisitRatings" :fields="['purposeOfVisitId', 'rating']">
         <template #cell(purposeOfVisitId)="row">
            {{ getPurposeOfVisit(row.item.purposeOfVisitId) }}
         </template>
        </b-table>
        </b-col>

        <b-col md="12">
          
          
          <p><h3>Arrange Blood Test:</h3> {{ survey.currentHealthStatus.arrangeAllergyTests.bloodTest? 'Yes' : 'No'  }}</p>
          <p><h3>Arrange Food Allergy Test:</h3> {{ survey.currentHealthStatus.arrangeAllergyTests.foodAllergyTest ? 'Yes' : 'No'  }}</p>
          
         </b-col>

      </b-row>
    </b-card>
  </div>

    </b-card>   


    </b-overlay>

    </div>


</template>


<script>
import * as SurveyRepo from "@/api-repo/surveys";

export default {
    inject: ["userStore"],
    name: "survey",
    data() {
        return {
            isBusy: null,
            survey: null
        }
    },
    async mounted() {
        const surveyId = this.$route.params.surveyId;
        console.log(surveyId)
        try {
           const resp = await SurveyRepo.getById(surveyId, this.userStore.state.token)
           console.log(resp)
           this.survey  = resp.data.data;
            console.log( this.survey )
        } catch (err) {
            console.error(err)
            // Handle error
        }
    },

    methods:{
        getPurposeOfVisit(id){
            if(!this.survey){
                return;
            }
            let found = this.survey.relatedCms.find(e => e._id === id);
            return found.published_value.en;

        }
    }
}
</script>


