<template>
  <div class="b-container">
    <b-overlay :show="isBusy">
      <b-card no-body>
        <b-card-header class="border-0">
          <base-button @click="newItem()" size="sm" type="primary"><i class="mr-1 fas fa-plus" />Add Stay</base-button>
        </b-card-header>
        <b-table v-if="renderComponent" class="w-100 text-capitalize" header-row-class-name="thead-light"
          :busy.sync="isBusy" :filter="search" :per-page="perPage" :currentPage="currentPage" :items="provider"
          :fields="fields">
          <template #cell(media_id)="row">
            <b-img style="max-height: 100px; width: 100px" thumbnail fluid
              :src="getImageById(row.item.media_id, 'thumbnails')" alt="Image 1"></b-img>
          </template>
      

          <template #cell(view)="row">
            <router-link 
            v-if='row.item.survey'
            :to="
              `/guests/${guestId}/surveys/` + row.item.survey._id + ''
            " class="p-2"><i class="fas fa-poll"></i></router-link>
          <p v-else>N/A</p>
          <span v-if="row.item.survey">  ({{ row.item.survey ? row.item.survey.status : 'N/A' }})</span>
          </template>

          <template #cell(actions)="row">

            <router-link :to="
              `/guests/${guestId}/stays/` + row.item._id + ''
            " class="p-2"><i class="fa-lg fas fa-arrow-right"></i></router-link>


            <delete-item-button style="display: inline; margin-left: 15px" @delete="
              () => {
                handleDelete(row.item._id);
              }
            "></delete-item-button>
          </template>



          <template #cell(hidden)="row">
            <span size="" class="badge badge-dot mr-4">
              <i v-if="row.item.hidden" class="bg-warning"></i>
              <i v-else class="bg-success"></i>
              <span class="status">{{
                row.item.hidden ? "Hidden" : "Visible"
              }}</span>
            </span>
          </template>
        </b-table>
        <b-card-footer class="py-4 d-flex justify-content-end">
          <base-pagination @change="reloadRows" v-model="currentPage" :per-page="perPage"
            :total="total"></base-pagination>
        </b-card-footer>
      </b-card>
    </b-overlay>
  </div>
</template>
  
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as stayRepo from "@/api-repo/stays";
import { dateformatter } from "@/util/dateformatter";
import { handleError, successMessage } from "@/util/error-handler";
import DeleteItemButton from "@/components/Base/DeleteItemButton.vue";

export default {
  name: "guestsList",
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    DeleteItemButton,
  },
  data() {
    return {
      renderComponent: true,
      guestId: this.$route.params.id,
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      total: 1,
      rows: [],
      search: null,
      fields: [
        { key: "checkin", label: "Checkin", formatter: dateformatter },
        { key: "checkout", label: "Checkout", formatter: dateformatter },
        { key: "advisor", label: "Advisor" },
        { key: "retreat", label: "Retreat" },
        { key: "view", label: "Related Survey" },
        { key: "sessioncountFiltered", label: "# of sessions" },
        { key: "last_updated_by", label: "By" },

        { key: "actions", label: "" },
      ],
    };
  },
  methods: {
    reloadRows: function (e) {
      this.currentPage = e;
    },
    newItem: function () {
      this.$router.push({ path: `/guests/${this.guestId}/stays/new` });
    },
    generateBreadCrumb() {
      return [{ label: "media" }];
    },



    mapItems: function (data) {
      data.forEach(row => {
        row.sessioncountFiltered = row.stayservices ? row.stayservices.filter(e => !e.deleted).length : 0;
      })

      return data.map((e) => {
        return {
          ...e,
          advisor: e.personnel ? e.personnel.fullname?.en : null,
          retreat: e.retreats ? e.retreats.name?.en : null,
        };
      });
    },
    handleDelete: async function (id) {
      try {
        await stayRepo.deleteR(id, this.userStore.state.token);
        successMessage("Item deleted successfully");
        this.forceRerender();
      } catch (e) {
        handleError(e);
      }
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    provider: async function (ctx) {
      this.isBusy = true;

      let result = await stayRepo.getStaysByGuestId(
        this.guestId,
        ctx.filter || "",
        ctx.currentPage,
        ctx.perPage,
        this.userStore.state.token
      );
      console.log(result)
      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      this.total =
        result.data.data[0].total.length && result.data.data[0].total[0].count;
      return this.mapItems(result.data.data[0].rows);
    },
  },
};
</script>
  

<style scoped>
.b-container {
  width: 100%;
  overflow-x: auto;
}</style>