<template>
  <div>
    
      <b-overlay :show="isBusy">
        <b-card class="pt-0">
         

          
          <b-card-header class="pt-0 pl-0 border-0">
              <a role="button" @click="$router.go(-1)" class="small pointer">
              <i class="mr-1 fas fa-angle-left"/>Health Conditions
              </a>
          </b-card-header>
           

          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(itemFormSubmit)"
              novalidate
            >
             

              <div>

                <b-row>
                <b-col lg="6">
                  <base-input type="select" :required="true" label="Stay" :name="'Stay'" placeholder="Select Stay"
                    v-model="itemForm.stayId" :options="mappedStays" :key-field="'_id'" :value-field="'_id'"
                    :text-field="'name'"></base-input>
                </b-col>
              </b-row>
                
                <b-row>
                  <b-col lg="6">
                    <base-input
                      type="select"
                      :required="true"
                      label="Condition"
                      :name="'Condition'"
                      placeholder="Select healthstatus"
                      v-model="itemForm.healthstatusId"
                      :options="healthstatusList"
                      :key-field="'_id'"
                      :value-field="'_id'"
                      :text-field="'name'"
                    ></base-input>
                  </b-col>

                  <b-col lg="6">
                    <base-input
                      type="select"
                      :required="true"
                      label="Advisor"
                      :name="'Advisor'"
                      placeholder="Select advisor"
                      v-model="itemForm.advisorId"
                      :options="advisors"
                      :key-field="'_id'"
                      :value-field="'_id'"
                      :text-field="'name'"
                    ></base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                     <base-datetime-input
                    :required="false"
                    name="Prognosis Date"
                    label="Prognosis Date"
                    v-model="itemForm.prognosisDate"
                    >
                    </base-datetime-input>

                    
           

                    
                  </b-col>

                  <b-col lg="6">
                    <base-input
                      :name="'Notes'"
                      label="Notes"
                      :rows="'5'"
                      placeholder="Notes"
                      :type="'textarea'"
                      v-model="itemForm.notes"
                    >
                    </base-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Visible</label>
                      <b-form-checkbox class="mb-3" v-model="itemForm.visible">
                        Hide from mobile app
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>

  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import * as GuestHealthRepo from "@/api-repo/guesthealthstatuses";
import * as PersonnelRepo from "@/api-repo/personnel";
import * as HealthRepo from "@/api-repo/healthstatuses";
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import { dateformatter, dateformatterWithTime } from "@/util/dateformatter";
import { handleError, successMessage } from "@/util/error-handler";
import * as StayRepo from "@/api-repo/stays";

export default {
  name: "stay-edit",
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    baseDatetimeInput
  },
  props: ["type"],
  data() {
    return {
      isBusy: false,
      guestId: this.$route.params.id,
      advisors: [],
      healthstatusList: [],
      mappedStays: [],
      itemForm: {
        healthstatusId: null,
        advisorId: null,
        prognosisDate: null,
        notes: null,
        visible: null,
        stayId: null
      },
    };
  },

  created() {
    if (this.isUpdate()) {
      this.getData();
    } else {
      this.getInitData();
    }
  },
  /*

media_id: 
    title: {en: , ar: }
    text: {en: , ar: }
    target_id: 
    target_type: 
    hidden: 
    tags: []
  */
  methods: {
generateBreadCrumb() {
        return [{label: "media"}];
      },
    isUpdate: function () {
      return this.$route.params.healthstatusId != null;
    },

    getInitData: async function () {
      await this.getStays();
      let advisorResult = await PersonnelRepo.getAdvisors(
        this.userStore.state.token
      );

      let healthResult = await HealthRepo.getList(this.userStore.state.token);
    
      this.advisors = [{ name: null, _id: null }, ...advisorResult.data.data];
      this.healthstatusList = [
        { name: null, _id: null },
        ...healthResult.data.data,
      ];
    },

    getStays: async function () {
      let results = await StayRepo.getStaysByGuestIdShortlist(this.guestId, this.userStore.state.token);
      this.mappedStays = results.data.data.length ? results.data.data.map(e => {
        return {
          name: `${dateformatterWithTime(e.checkin)}|${dateformatterWithTime(e.checkout)}`,
          _id: e._id
        }
      }) : [];
   
    },


    getData: async function () {
      try {
        await this.getInitData();
        this.isBusy = true;
        let result = await GuestHealthRepo.getById(
          this.$route.params.healthstatusId,
          this.userStore.state.token
        );

        if (result.data.data) {
          Object.keys(this.itemForm).forEach((field) => {
            //if(field === 'title'){
            //}
            this.itemForm[field] = result.data.data[field];
          });
        }
        console.log(this.itemForm)
        this.$forceUpdate();
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;
        let submissionForm = {
          ...this.itemForm,
          //advisor: this.itemForm.advisorId ? this.advisors.find(e => e._id === this.itemForm.advisorId)?.name : null, 
          //healthstatus: this.itemForm.healthstatusId ? this.healthstatusList.find(e => e._id === this.itemForm.healthstatusId)?.name : null, 
        }
        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            await GuestHealthRepo.create(
              this.$route.params.id,
              submissionForm,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({
              path: `/guests/${this.$route.params.id}/healthstatus`,
            });
            //path: '/guests/:id/stays',
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            this.isBusy = true;
            await GuestHealthRepo.update(
              this.$route.params.healthstatusId,
              submissionForm,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
