<template>
  <div>
    <validation-provider
      :rules="required ? 'required' : ''"
      :name="name"
      v-bind="$attrs"
      v-slot="{ errors, valid, invalid, validated }"
    >
      <b-form-group :class="{ 'mb-0': nomargin }">
        <slot name="label">
          <label v-if="label" :class="labelClasses">
            {{ label }}<span v-if="required">&nbsp;*</span>
          </label>
        </slot>
        <slot v-bind="slotData">
          <b-input-group  class="base-calendar-input-group">
            <b-form-input
              id="example-input"
              v-model="inputVal"
              type="text"
              placeholder="HH:mm:ss"
              autocomplete="off"
              :class="[
                { 'is-valid': valid && validated && successMessage },
                { 'is-invalid': invalid && validated },
              ]"
            ></b-form-input>
            <b-input-group-append>
               <b-form-timepicker
                        v-model="inputVal"
                button-only
                right
                aria-controls="example-input"
                @context="onContext"
                    ></b-form-timepicker>

            
            </b-input-group-append>
          </b-input-group>

          <slot name="success">
            <div
              class="valid-feedback"
              v-if="valid && validated && successMessage"
            >
              {{ successMessage }}
            </div>
          </slot>
          <slot name="error">
            <div
              v-if="errors[0]"
              class="invalid-feedback"
              style="display: block"
            >
              {{ errors[0] }}
            </div>
          </slot>
        </slot>
      </b-form-group>
    </validation-provider>
  </div>
</template>
 
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
    nomargin: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    trackby: {
      type: String,
      default: "value",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
    multiple: {
      type: Boolean,
      default: true,
      description: "Whether input is required (adds an asterix *)",
    },
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    value: {},
    label: {
      type: String,
      description: "Label",
    },
    options: {
      type: Array,
      description: "Array of options",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
  },
  data() {
    return {
      // data: [...this.selected]
    };
  },
  methods: {
    updateValue(evt) {
      console.log("update");
      console.log(evt);
      //let value = evt;
      //this.$emit("input", this.value);
    },
    onFocus(evt) {
      console.log("focus");
      this.focused = true;
      this.$emit("focus", evt);
    },
    onBlur(evt) {
      console.log("blur");
      this.focused = false;
      this.$emit("blur", evt);
    },
      onContext(evt){
      
    }

  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        // focus: this.onFocus,
        // blur: this.onBlur,
      };
    },
    slotData() {
      return {
        error: this.error,
        ...this.listeners,
      };
    },

    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

