
import moment from "moment";
export const dateformatter= (date) => {
    if(!date){
        return null;
    }
    return   moment(date).format('yyyy-MM-DD');
  }
  

  export const dateformatterWithTime = (date) => {
    if(!date){
        return null;
    }
    return   moment(date).format('yyyy-MM-DD hh:mm');
  }


  export const longdateformatter= (date) => {
    if(!date){
        return null;
    }
    return   moment(date).format('MMMM Do YYYY, h:mm:ss a');
  }
  
