var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('label',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('span',[_vm._v(" *")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex align-items-start justify-content-start"},[_vm._l((_vm.value),function(item,index){return _c('div',{key:index,staticClass:"m-1 d-flex flex-column justify-content-center align-items-center"},[_c('media-input',{attrs:{"format":_vm.format,"name":_vm.name + index,"label":"","guestId":_vm.guestId,"nomargin":true,"required":true},on:{"remove":function($event){return _vm.deleteImage(index)}},model:{value:(_vm.value[index]),callback:function ($$v) {_vm.$set(_vm.value, index, $$v)},expression:"value[index]"}})],1)}),_c('div',{staticClass:"m-1 rounded border-gray border-1 border d-flex align-items-center justify-content-center",staticStyle:{"width":"100px","height":"100px","cursor":"pointer"},on:{"click":function($event){return _vm.addItem()}}},[_c('i',{staticClass:"fa fa-plus"})])],2),_c('validation-provider',_vm._b({attrs:{"rules":"min_value:1","name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',[_c('div',[_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({staticClass:"form-control",class:[
              { 'is-valid': valid && validated && _vm.successMessage },
              { 'is-invalid': invalid && validated } ],attrs:{"hidden":"true","valid":valid,"required":_vm.required},domProps:{"value":_vm.value.length}},'input',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),_vm._t("infoBlock")],2),_vm._t("success",function(){return [(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]}),_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" At least one image is required ")]):_vm._e()]})],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }