<template>
  <validation-provider
    :rules="rules"
    :name="name"
    v-bind="$attrs"
    v-slot="{ errors, valid, validate, validated, invalid }"
  >

    <b-form-group>
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{ label }}<span v-if="required">*</span>
        </label>
      </slot>

      <div class="custom-file">
        <input
          type="file"
           :required="required"
          class="custom-file-input"
          :class="[
              { 'is-valid': valid && validated && successMessage },
              { 'is-invalid': invalid && validated }]"
          id="customFileLang"
         
          @change="validate"
         
          v-on="listeners"
        />
        <label class="custom-file-label" for="customFileLang">
          {{ filename }}
        </label>
      </div>

      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{ successMessage }}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block">
          {{ errors[0] }}
        </div>
      </slot>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  name: "file-input",
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean
    },
    initialLabel: {
      type: String,
      default: "Select file",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    successMessage: {
      type: String
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label",
    },
     name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
  },
  data() {
    return {
      files: [],
      valid: false
    };
  },
  computed: {
    
   

    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        change: this.fileChange,
       // input: this.updateValue,
      //  focus: this.onFocus,
       // blur: this.onBlur,
      };
    },
    filename() {
      let fileNames = [];
      for (let file of this.files) {
        fileNames.push(file.name);
      }
      return fileNames.length ? fileNames.join(", ") : this.initialLabel;
    },
  },
  methods: {
    fileChange(evt) {
      this.files = evt.target.files;
      this.$emit("change", this.files);
    },
  },
};
</script>
<style>
</style>
