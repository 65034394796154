import axios from "axios"



export const create = (item, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `retreats/`, {
    media_id: item.media_id,
    description: item.description,
    name: item.name,
    resort_id: item.resort_id,
    visible: item.visible || false,
    sort_order: item.sort_order,
    syncNames: item.syncNames || [],
    
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `retreats/` + id, {
    media_id: item.media_id,
    description: item.description,
    name: item.name,
    type: item.type,
    visible: item.visible || false,
    sort_order: item.sort_order,
    syncNames: item.syncNames || [],
  
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  console.log(id)
  return axios.get(process.env.VUE_APP_API_URL + `retreats/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getList = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `retreats/list`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const get = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `retreats/?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `retreats/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

/*export const getByType = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `categories/type/${type}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}*/
