<template>
  <div>
    <b-overlay :show="isBusy">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <b-row>
                <b-col>
                  <base-button @click="newItem()" size="sm" type="primary"
                    ><i class="mr-1 fas fa-plus" />Add Condition</base-button
                  >
                </b-col>
              </b-row>
            </b-card-header>

            <b-table
              v-if="renderComponent"
              class="w-100 text-capitalize"
              header-row-class-name="thead-light"
              :busy.sync="isBusy"
              :filter="search"
              :per-page="perPage"
              :currentPage="currentPage"
              :items="provider"
              :fields="fields"
            >
              <template #cell(image)="row">
                <b-img
                  style="max-height: 100px; width: 100px"
                  thumbnail
                  fluid
                  :src="getSourceFromFile(row.item.file.filename, 'thumbnails')"
                  alt="Image 1"
                ></b-img>
              </template>
              <template #cell(tags)="row">
                <div style="font-size: 1rem">
                  <b-badge
                    class="mx-1"
                    v-for="(tag, index) in row.item.tags"
                    :key="index"
                    variant="secondary"
                    >{{ tag }}</b-badge
                  >
                </div>
              </template>

              <template #cell(relatedStay)="row">
                    <router-link 
                    v-if="row.item.stays"
                    :to="`/guests/${$route.params.id}/stays/${row.item.stays._id}`"><i
                        class='fas fa-bed '></i></router-link>
                      <p v-else>N/A</p>
                      <span  v-if="row.item.stays"> {{  dateformatterWithTime(row.item.stays.checkin)}}|{{ dateformatterWithTime(row.item.stays.checkout) }}</span>

                  </template>

              <template #cell(actions)="row">
                <router-link
                  :to="
                    `/guests/${guestId}/healthstatus/` + row.item._id 
                  " class="p-2"><i class="fa-lg fas fa-arrow-right"></i
                ></router-link>
                
                  <delete-item-button
                  style="display: inline; margin-left: 15px"
                  @delete="
                    () => {
                      handleDelete(row.item._id);
                    }
                  "
                ></delete-item-button>

              </template>

              <template #cell(visible)="row">
                <span size="" class="badge badge-dot mr-4">
                  <i v-if="!row.item.visible" class="bg-warning"></i>
                  <i v-else class="bg-success"></i>
                  <span class="status">{{
                    row.item.visible ? "Visible" : "Hidden"
                  }}</span>
                </span>
              </template>
            </b-table>
            <b-card-footer class="py-4 d-flex justify-content-end">
              <base-pagination
                @change="reloadRows"
                v-model="currentPage"
                :per-page="perPage"
                :total="total"
              ></base-pagination>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
  <script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as Repo from "@/api-repo/guesthealthstatuses";
import { fullname } from "@/util/fullname";
import { capitalize } from "@/util/capitalize";
import FileInput from "@/components/Inputs/FileInput.vue";
import { getSourceFromFile } from "@/util/imageSource";
import { dateformatter, dateformatterWithTime } from "@/util/dateformatter";
import { handleError, successMessage } from "@/util/error-handler";
import DeleteItemButton from "@/components/Base/DeleteItemButton.vue";

export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    FileInput,
    DeleteItemButton
  },
  data() {
    return {
      renderComponent: true,
      dateformatterWithTime: dateformatterWithTime,
      guestId: this.$route.params.id,
      showList: false,
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      total: 1,
      rows: [],
      search: null,
      fields: [
        { key: "healthstatus", label: "HealthStatus" },
        { key: "advisor", label: "Advisor" },
        {
          key: "prognosisDate",
          label: "Prognosis Date",
          formatter: dateformatter,
        },
        { key: "last_updated_by", label: "By" },
        { key: "relatedStay", label: "Related Stay" },
        { key: "actions", label: "" },
      ],
    };
  },
  computed: {},
  methods: {
    generateBreadCrumb() {
        return [{label: "media"}];
      },
    getSourceFromFile,
    fullname,
    capitalize,

    reloadRows: function (e) {
      this.currentPage = e;
    },
    newItem: function () {
      this.$router.push({
        path: `/guests/${this.$route.params.id}/healthstatus/new`,
      });
    },

    mapItems: function (data) {
      return data.map((e) => {
        return {
          ...e,
          advisor: e.personnel ? e.personnel.fullname?.en : null,
          healthstatus: e.healthstatuses ? e.healthstatuses.title?.en : null,
        };
      });
    },


    
    handleDelete: async function (id) {
      try {
        await Repo.deleteR(id, this.userStore.state.token);
        successMessage("Item deleted successfully");
        this.forceRerender();
      } catch (e) {
        handleError(e);
      }
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    provider: async function (ctx) {
      this.isBusy = true;
      let result = await Repo.getAllByGuestId(
        this.$route.params.id,
        ctx.filter || "",
        ctx.currentPage,
        ctx.perPage,
        this.userStore.state.token
      );
      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      this.total =
        result.data.data[0].total.length && result.data.data[0].total[0].count;
      return this.mapItems(result.data.data[0].rows);
    },
  },
};
</script>
  