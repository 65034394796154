<template>
  <div>
    <b-modal size="xl" ref="guest-modal" hide-footer title="Guest Picker">
      <div class="d-block text-center">
        <form @submit="doSearch()">
          <base-input
            type="text"
            v-model="search"
            append-icon="fas fa-search" 
          />
        </form>
      </div>
   
        <div class="row mb-5">
          <div @click="selectGuest(item)"  v-for="(item,index) in items" :key="index" class="item col-12 col-md-6 col-lg-4">
            <div class="d-flex flex-column align-items-center mb-2">
              <div style="width:100%;" class="thumbnail bg-light shadow-sm rounded p-2">
                {{item.first_name.en}} {{item.last_name.en}}
                <h5>{{item.email || '@email.com'}}</h5>
              </div>
            <div></div>
            <div style="font-size:1rem"><b-badge class="mx-1" v-for="(tag, index) in item.tags" :key="index"  variant="secondary">{{tag}}</b-badge></div>
            </div>
          </div>
        </div>
        
        <div class="d-flex flex-row justify-content-center">
         
            <base-pagination class="flex-0"  @change="reloadRows" v-model="currentPage" :per-page="perPage" :total="total"></base-pagination>
        </div>
       
    </b-modal>

    <validation-provider
      :rules="rules"
      :name="name"
      v-bind="$attrs"
      v-slot="{ errors, valid, invalid, validated }"
    >
      <b-form-group :class="{'mb-0': nomargin}">
        
        <slot name="label">
          <label v-if="label" class="form-control-label">
            {{ label }}<span v-if="required">&nbsp;*</span>
          </label>
        </slot>
        <div>
          <slot v-bind="slotData">
            <div @click="showModal"
              class="  d-flex justify-content-stretch"
              >
              <div  class="bg-light flex-1 w-100  rounded-lg p-2" role="button" >
                <i v-if="!selectedGuest" class="fas fa-user"/>
                <div v-else>
                  {{selectedGuest.first_name.en}} {{selectedGuest.first_name.ar}}<br/>
                  <h5>{{selectedGuest.email || '@email.com'}}</h5>
                  <i class="float-right fa fa-trash" @click.stop="clearGuest()"></i>
                </div>
              </div>
              
            </div>
              
            <input
              :hidden="true"
              :value="value"
              type="hidden"
              v-on="listeners"
              v-bind="$attrs"
              :valid="valid"
              :required="required"
              class="form-control"
              :class="[
                { 'is-valid': valid && validated && successMessage },
                { 'is-invalid': invalid && validated },
                
              ]"
            />
          </slot>
          <slot name="infoBlock"></slot>
        </div>
        <slot name="success">
          <div
            class="valid-feedback"
            v-if="valid && validated && successMessage"
          >
            {{ successMessage }}
          </div>
        </slot>
        <slot name="error">
          <div v-if="errors[0]" class="invalid-feedback" style="display: block">
            Please select a guest
          </div>
        </slot>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<style scoped>
  .item {
    cursor: pointer;
  }

  .item_field {
    overflow:hidden;
     cursor:pointer
  }
  .item_field:hover {
    border-color:black !important;
    
  }
  
</style>

<script>
import { getImageById, getSourceFromFile } from "@/util/imageSource";
import * as guestRepo from '@/api-repo/guests'

export default {
  inheritAttrs: false,
  name: "media-input",
  inject: ["userStore"],
  props: {
    format: {
      type: String,
      default: 'image'
    },
    guestId:{
      type: String,
      default: null
    },
    nomargin: {
      type: Boolean
    },
    required: {
      type: Boolean
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
   
    value: {
      type: [String, Number, Object],
      description: "Input value",
    },
   
   
    rules: {
      type: [String, Array, Object],
      description: "Vee validate validation rules",
      default: "",
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
  },
  data() {
    return {
      //media: null,
      selectedGuest: null,
      items: [],
      searchTerm: null,
      isBusy: false,
      currentPage: 1,
      perPage: 6,
      total: 1,
     // rows: [],
      search: null,
     
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      };
    },
    slotData() {
      return {
        error: this.error,
        ...this.listeners,
      };
    },
  },
  created() {
    this.getGuestPage();
  },
  methods: {
     getImageById,
     getSourceFromFile,
    doSearch() {
      this.getGuestPage()
    },
    selectGuest(item) {
      //this.media = item
      let value = item._id;
      this.selectedGuest = item;
      //this.value = item.id;
      this.$emit("input", value);
      this.hideModal();
    },
    showModal() {
      this.$refs['guest-modal'].show()
    },
    hideModal() {
      this.$refs['guest-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['guest-modal'].toggle('#toggle-btn')
    },
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
     clearGuest() {
       this.selectedGuest = null;
       this.$emit("input", null);
      this.$emit("remove", null);
    },
   async getGuestPage() {
      this.isBusy = true;
      let result = 
      await guestRepo.get('',  this.search || '', this.currentPage, this.perPage, this.userStore.state.token);
      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      this.total = result.data.data[0].total.length && result.data.data[0].total[0].count;
      this.items =  result.data.data[0].rows;
    },
    reloadRows: function(e) {
      
      this.currentPage = e
      this.getGuestPage();
    },
  },
};
</script>
<style scoped>
.item .thumbnail:hover {
  background-color:white;

}
</style>
