<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">

          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button @click="newItem()" size="sm" type="neutral"><i class="mr-1 fas fa-plus"/>Add Guest</base-button>
        </b-col>
      </b-row>
    </base-header>
  <b-container fluid class="mt--6">
    <b-row>
      <b-col >
        <b-card no-body>
          <b-card-header class="border-0">
            <b-row>
              <b-col >
              </b-col>
              <b-col cols="3">
              <form>
                <b-form-group class="mb-0">
                  <b-input-group >
                    <b-input-group-prepend is-text>
                      <i class="fas fa-search" />
                    </b-input-group-prepend>
                    <b-form-input v-model="search" placeholder="Search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </form>
          </b-col>
          </b-row>
          </b-card-header>
          <b-overlay :show="isBusy">
          <b-table class=" w-100 text-capitalize"
            v-if="renderComponent"
            header-row-class-name="thead-light"
            :busy.sync="isBusy"
            :filter="search"
            :per-page="perPage"
            :currentPage="currentPage"
            :items="provider"
            :fields="fields"
           
            >
            
            <template #cell(media_id)="row" >
              <b-img style="max-height:100px;width:100px;" thumbnail fluid :src="getImageById(row.item.media_id, 'thumbnails')" alt="Image 1"></b-img>
            </template>
            <template #cell(actions)="row">
              <router-link :to="{name: routeNames.routeGuestMain, params: {id: row.item._id }}" class="p-2"><i class="fa-lg fas fa-arrow-right"></i></router-link>
                   <delete-item-button
                      style="display:inline; margin-left:15px"
                        @delete="
                          () => {
                            handleDelete(row.item._id);
                          }
                        "
                      ></delete-item-button>
            </template>
            <template #cell(hidden)="row">
              <span size="" class="badge badge-dot mr-4  ">
                <i v-if="row.item.hidden" class="bg-warning"></i>
                <i v-else class="bg-success"></i>
                <span class="status">{{row.item.hidden ? 'Hidden' : 'Visible'}}</span>
              </span>
            </template>
        </b-table>
      </b-overlay>
        <b-card-footer class="py-4 d-flex justify-content-end">
            <base-pagination @change="reloadRows" v-model="currentPage" :per-page="perPage" :total="total"></base-pagination>
        </b-card-footer>
    </b-card>
  </b-col>
</b-row>
</b-container>
</div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
 
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import * as guestRepo from '@/api-repo/guests';
import { getImageById } from "@/util/imageSource";
import {dateformatter} from "@/util/dateformatter"
import { handleError, successMessage } from "@/util/error-handler";
import DeleteItemButton from "@/components/Base/DeleteItemButton.vue";
import * as routeNames from "@/routes/route-names"
export default {
  name:'guestsList',
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    DeleteItemButton
  },
  props: ['type'],
  data() {
    return {
      routeNames: routeNames,
      renderComponent:true,
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      total: 1,
      rows: [],
      search: null,
      fields: [
      {key: 'title.en', label: 'Title'},
        {key: 'fullname.en', label: 'Fullname'},
        {key: 'lastsynced', label: 'Last Sync', formatter: dateformatter},
        {key: 'last_updated', label: 'Last Updated', formatter: dateformatter},
        {key: 'last_updated_by', label: 'By'},
        {key: 'actions', label: ''},
      ],
    };
  },
  methods: {
    generateBreadCrumb() {
        return [{label: "Guests"}];
      },
 
    getImageById,
    reloadRows: function(e) {
      this.currentPage = e
    },
    newItem: function() {
      this.$router.push({name: routeNames.routeGuestsNew})
    },

      handleDelete: async function (id) {
      try {
        await guestRepo.deleteR(id, this.userStore.state.token);
        successMessage("Item deleted successfully");
        this.forceRerender();
      } catch (e) {
        handleError(e);
      }
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    provider: async function(ctx) {

      this.isBusy = true;
      let result = await guestRepo.get(this.type, ctx.filter || '', ctx.currentPage, ctx.perPage, this.userStore.state.token);
      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      this.total = result.data.data[0].total.length && result.data.data[0].total[0].count;
      return result.data.data[0].rows;

    },
  }
};
</script>
