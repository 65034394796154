var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validate = ref.validate;
var validated = ref.validated;
var invalid = ref.invalid;
return [_c('b-form-group',[_vm._t("label",function(){return [(_vm.label)?_c('label',{class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e()]}),_c('div',{staticClass:"custom-file"},[_c('input',_vm._g({staticClass:"custom-file-input",class:[
            { 'is-valid': valid && validated && _vm.successMessage },
            { 'is-invalid': invalid && validated }],attrs:{"type":"file","required":_vm.required,"id":"customFileLang"},on:{"change":validate}},_vm.listeners)),_c('label',{staticClass:"custom-file-label",attrs:{"for":"customFileLang"}},[_vm._v(" "+_vm._s(_vm.filename)+" ")])]),_vm._t("success",function(){return [(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]}),_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]})],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }