<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb
              :crumbs="generateBreadCrumb()"
            ></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card>
        <b-overlay :show="isBusy">
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(submit)"
              novalidate
            >
            <b-row class="justify-content-center">
              <b-col lg="4">
                <h6 class="heading-small text-muted mb-2">Guest</h6>
                <guest-input v-model="guest" :required="true" />
              </b-col>
            </b-row>
            <b-row class="justify-content-center">
              <b-col lg="4">
                <h6 class="heading-small text-muted mb-2">NEXT SESSION</h6>
                <div v-if="sessions && sessions.length" class="bg-white shadow border rounded-lg color-black p-2 w-100">
                <h3>{{sessions[0].services.name.en}}</h3>
                <h4><i class="fa fa-calendar mr-2" />{{longdateformatter(sessions[0].datetime)}}</h4>
                <h4><i class="fa fa-clock mr-2" />{{sessions[0].services.duration}}</h4>
                <h4 :class="sessions[0].status.toUpperCase() == 'SCHEDULED' ? 'text-warning' : 'text-success'">{{sessions[0].status.toUpperCase()}}</h4>
                <h4 v-if="canCheckIn(sessions[0]) != ''"><i class="text-danger fa mr-2 fa-exclamation-triangle" />{{canCheckIn(sessions[0])}}</h4>
              </div>
              <div v-else-if="guest != null">
                <h4><i class="text-danger fa mr-2 fa-exclamation-triangle" />No upcoming sessions for guest found!</h4>
              </div> 
              </b-col>


              
            </b-row>
            <b-row class="justify-content-center mt-4">
              <b-col lg="4">
                <base-button :disabled="!guest || !sessions || sessions.length == 0" type="primary" native-type="submit" >Check In</base-button>
              </b-col>
            </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>
  <script>
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import guestInput from "@/components/Inputs/GuestInput";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as routeNames from "@/routes/route-names";
import * as SessionsRepo from "@/api-repo/staysessions";
import { handleError, successMessage } from "@/util/error-handler";
import { longdateformatter } from "@/util/dateformatter";
import { getCountriesList, getLanguages } from "@/util/countries";
import moment from "moment";

export default {
  name: "guest-edit",
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    baseDatetimeInput,
    guestInput
  },
  props: ["type"],
  data() {
    return {
      isBusy: false,
      guest: null,
      sessions: null,
    };
  },
  created() {
    this.getInitData();
  },
  watch: {
    guest: function(newv) {
      this.sessions = null;
      this.$nextTick(() => {
       this.getSessionsForGuest();
      });
     
    }
  },
  methods: {
    longdateformatter,
    generateBreadCrumb() {
      return [{ label: "Check In" }];
    },
    canCheckIn(session) {
      if (session.status != "scheduled") {
        return "Guest is already checked in for upcoming session"
      }
      if (moment.duration(moment().diff(moment(session.datetime))).asMinutes() > 15) {
        return "Check in not allowed after 15 minutes of session start";
      } else if (moment.duration(moment().diff(moment(session.datetime))).asMinutes() < -5) {
        return "Check in is allowed 5 minutes before session start";
      } else {
        return ""
      }
    },
    getInitData: async function () {},
    getSessionsForGuest: async function() {
      if (!this.guest) return;

       try {
          this.isBusy = true;
          let res = await SessionsRepo.getUpcomingGuest(
            this.guest,
            this.userStore.state.token
          );
 
          this.sessions = res.data.data;
        } catch (e) {
          alert(e)
          handleError(e);
        } finally {

          this.isBusy = false;
        }
    },
    submit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid && (this.canCheckIn(this.sessions && this.sessions.length && this.sessions[0]) == "")) {
        try {
          this.isBusy = true;
          let res = await SessionsRepo.checkin(
            this.sessions[0]._id,
            this.userStore.state.token
          );
          successMessage("Guest checked in successfully");
          this.guest = null;
          this.sessions = null;
        } catch (e) {
          handleError(e);
        } finally {
          this.isBusy = false;
        }
      }
    },
  },
};
</script>
  