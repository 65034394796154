<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb
              :crumbs="generateBreadCrumb()"
            ></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(itemFormSubmit)"
              novalidate
            >
              <h6 class="heading-small text-muted mb-4">Content</h6>

              <div class="">
                <b-row>
                  <b-col lg="6">
                    <html-editor
                      :name="'Content - English'"
                      label="Content - English"
                      :rows="'5'"
                      :required="true"
                      placeholder="Content - English"
                      :type="'textarea'"
                      v-model="itemForm.content.en"
                    >
                    </html-editor>
                  </b-col>

                  <b-col lg="6">
                    <html-editor
                      :name="'Content - Arabic'"
                      label="Content - Arabic"
                      :rows="'5'"
                      :required="true"
                      placeholder="Content - Arabic"
                      :type="'textarea'"
                      v-model="itemForm.content.ar"
                    ></html-editor>
                  </b-col>

                </b-row>
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ "Update" }}
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import moment from "moment";
import MediaInput from "@/components/Inputs/MediaInput";
import * as repo from "@/api-repo/generic-content";

import { handleError, successMessage } from "@/util/error-handler";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    MediaInput,
    HtmlEditor,
  },
  data() {
    return {
      isBusy: false,
      categories: [],
      types: [
        { name: "Recipe", value: "recipe" },
        { name: "Handout", value: "handout" },
      ],
      resorts: [
        { name: "None", value: null },
        { name: "Serenity", value: "serenity" },
        { name: "Discovery", value: "discovery" },
      ],
      itemForm: {
        content: { en: null, ar: null },
      },
    };
  },
  created() {
      this.getData();
  },
 
  methods: {
    generateBreadCrumb() {
      return [
      {label: "Terms & Conditions", route: "TermsEdit"}
      ];
    },

    getData: async function () {
      try {
        this.isBusy = true;
        let result = await repo.get(
          'terms',
          this.userStore.state.token
        );
        if(result.data.data){
        this.itemForm.content = result.data.data.content;
        }
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },
 
    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;
        try {
          this.isBusy = true;
          let result = await repo.update(
            'terms',
            this.itemForm,
            this.userStore.state.token
          );
          successMessage("Item updated successfully");
        } catch (e) {
          handleError(e);
        } finally {
          this.isBusy = false;
        }
      }
    },
  },
};
</script>
