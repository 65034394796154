


<template>
    <b-card no-body>
        <b-row>
            <b-col lg="12">
                <b-overlay :show="isSurveysBusy">
                    <b-card class="shadow-none" no-body>
                        <b-card-header class="border-0">
                        <b-row>
                            <b-col>
                                    <!--  <base-button @click="newSurvey()" size="sm" type="primary">
                                      <i class="mr-1 fas fa-plus"></i>Add New
                                    </base-button> -->
                                </b-col>
                            </b-row>
                        </b-card-header>
                        <b-table v-if="renderComponent" class="w-100 text-capitalize" header-row-class-name="thead-light"
                            :busy.sync="isSurveysBusy" :per-page="perPage" :currentPage="currentPage" :items="surveys"
                            :fields="fields">
                            <template #cell(name)="row">
                                {{ row.item.firstName + ' ' + row.item.lastName }}
                            </template>
                   
                            <template #cell(view)="row">
                                <router-link :to="`/guests/${guestId}/stays/${row.item.stayId}`"><i class='fas fa-bed '></i></router-link>
                            </template>
                            <template #cell(details)="row">

                                <router-link :to="
                                    `/guests/${guestId}/surveys/` + row.item._id + ''
                                " class="p-2"><i class="fa-lg fas fa-arrow-right"></i></router-link>

                            </template>
                            <template #cell(delete)="row">

                                <delete-item-button style="display: inline; margin-left: 15px" @delete="
                                    () => {
                                        handleDelete(row.item);
                                    }
                                "></delete-item-button>

                            </template>
                        </b-table>
                        <b-card-footer class="py-4 d-flex justify-content-end">
                            <base-pagination @change="reloadRows" v-model="currentPage" :per-page="perPage"
                                :total="total"></base-pagination>
                        </b-card-footer>
                    </b-card>
                </b-overlay>
            </b-col>
        </b-row>
    </b-card>
</template>
  
<script>
import * as SurveyRepo from "@/api-repo/surveys";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb.vue";
import { handleError, successMessage } from "@/util/error-handler";
import DeleteItemButton from "@/components/Base/DeleteItemButton.vue";
import { dateformatter } from "@/util/dateformatter";

export default {
    inject: ["userStore"],
    name: "surveys",
    components: {
        RouteBreadCrumb,
        DeleteItemButton
    },
    data() {
        return {
            guestId: this.$route.params.id,
            isSurveysBusy: false,
            perPage: 10,
            currentPage: 1,
            total: 0,
            fields: [
            { key: 'Name', label: 'Name on survey' },
                { key: 'view', label: 'Related Stay' },
                { key: 'last_updated', label: 'Updated' , formatter: dateformatter },
                { key: 'last_updated_by', label: 'Updated By'  },
                { key: 'details', label: 'Details' },
                { key: 'delete', label: 'Delete' }
            ],
            surveys: [],
        }
    },
    computed: {
        renderComponent: {
            get() {
                return this.surveys.length > 0
            },
            set(value) {
                // Do something when renderComponent is set to a new value
            }
        }
    },
    created() {
        this.loadSurveys();
    },
    methods: {
        async loadSurveys() {
            try {
                this.isSurveysBusy = true;
                const response = await SurveyRepo.getAllSurveys(this.userStore.state.token, this.$route.params.id);
                this.surveys = response.data.data.rows;
                console.log(this.surveys)
                this.total = response.data.data.total;
                this.renderComponent = true;
            } catch (error) {
                console.error(error);
            } finally {
                this.isSurveysBusy = false;
            }
        },



        async handleDelete(survey) {
            try {
                this.isSurveysBusy = true;
                await stayRepo.deleteR(id, this.userStore.state.token);
                this.surveys = this.surveys.filter(s => s.id !== survey.id);
                successMessage("Item deleted successfully");
                this.forceRerender();
            } catch (error) {
                console.error(error);
            } finally {
                this.isSurveysBusy = false;
            }
        },

        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },


   
        reloadRows() {
            this.loadSurveys();
        }
    }
}
</script>
