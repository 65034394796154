<template>
  <div>
    <b-overlay :show="isBusy">
      <b-row>
        <b-col>
          <b-card class="shadow-none" no-body>
            <b-card-header class="border-0">
              <b-row>
                <b-col> </b-col>
                <b-col cols="3">
                  <form>
                    <b-form-group class="mb-0">
                      <b-input-group>
                        <!--   <b-input-group-prepend is-text>
                            <i class="fas fa-search" />
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="search"
                            placeholder="Search"
                          ></b-form-input> -->
                      </b-input-group>
                    </b-form-group>
                  </form>
                </b-col>
              </b-row>
            </b-card-header>

            <b-table
              v-if="renderComponent"
              class="w-100 text-capitalize"
              header-row-class-name="thead-light"
              :busy.sync="isBusy"
              :filter="search"
              :per-page="perPage"
              :currentPage="currentPage"
              :items="provider"
              :fields="fields"
            >
              <template #cell(tags)="row">
                <div style="font-size: 1rem">
                  <b-badge
                    class="mx-1"
                    v-for="(tag, index) in row.item.tags"
                    :key="index"
                    variant="secondary"
                    >{{ tag }}</b-badge
                  >
                </div>
              </template>
              <template #cell(actions)="row">
                <i
                  @click="
                    () => {
                      openEdit(row.item._id);
                    }
                  "
                  class="fas  editStaySesstionBtn"
                ></i>
                <delete-item-button
                  style="display: inline; margin-left: 15px"
                  @delete="
                    () => {
                      handleDelete(row.item._id);
                    }
                  "
                ></delete-item-button>
              </template>
              <template #cell(visible)="row">
                <span size="" class="badge badge-dot mr-4">
                  <i v-if="!row.item.visible" class="bg-warning"></i>
                  <i v-else class="bg-success"></i>
                  <span class="status">{{
                    row.item.visible ? "Visible" : "Hidden"
                  }}</span>
                </span>
              </template>
            </b-table>
            <b-card-footer class="py-4 d-flex justify-content-end">
              <base-pagination
                @change="reloadRows"
                v-model="currentPage"
                :per-page="perPage"
                :total="total"
              ></base-pagination>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <ModalGuestStaySessionInfoEdit
      ref="modal"
      :staySessionId="staySessionId"
      @refresh="forceRerender"
      @closeModal="closeSessionModal"
      :show-modal="editSessionModal"
    />
  </div>
</template>
  
  <script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as guestStaySessionRepo from "@/api-repo/staysessions";
import { fullname } from "@/util/fullname";
import { capitalize } from "@/util/capitalize";
import FileInput from "@/components/Inputs/FileInput.vue";
import { booleanFormatter } from "@/util/booleanFormatter";
import { dateformatter, dateformatterWithTime } from "@/util/dateformatter";
import ModalGuestStaySessionInfoEdit from "./Modal_GuestStaySessionInfoEdit.vue";
import DeleteItemButton from "@/components/Base/DeleteItemButton.vue";
import { handleError, successMessage } from "@/util/error-handler";

export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    FileInput,
    ModalGuestStaySessionInfoEdit,
    DeleteItemButton,
  },
  data() {
    return {
      guestId: this.$route.params.id,
      showList: false,
      editSessionModal: false,
      staySessionId: null,
      renderComponent: true,
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      total: 1,
      rows: [],
      search: null,
      fields: [
        { key: "serviceName", label: "Treatment/Activity" },
        { key: "status", label: "Status" },
        { key: "included", label: "Included", formatter: booleanFormatter },
        { key: "datetime", label: "Start datetime", formatter: dateformatterWithTime },
        { key: "endDatetime", label: "End datetime", formatter: dateformatterWithTime },
        {
          key: "last_updated",
          label: "Last updated",
          formatter: dateformatter,
        },
        { key: "last_updated_by", label: "By" },
        { key: "actions", label: "" },
      ],
    };
  },
  computed: {},
  methods: {
    reloadData() {
      this.$refs.modal.refreshData();
    },
    openEdit(id) {
      this.staySessionId = id;
      setTimeout(() => {
        this.editSessionModal = true;
        this.reloadData();
      }, 500);
    },

    generateBreadCrumb() {
      return [{ label: "media" }];
    },
    fullname,
    capitalize,

    reloadRows: function (e) {
      this.currentPage = e;
    },

    closeSessionModal() {
      this.editSessionModal = false;
    },

    handleDelete: async function (id) {
      try {
        await guestStaySessionRepo.deleteR(id, this.userStore.state.token);
        successMessage("Item deleted successfully");
        this.forceRerender();
      } catch (e) {
        handleError(e);
      } finally {
        this.$emit("refresh");
      }
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },

    provider: async function (ctx) {
      this.isBusy = true;
      let result = await guestStaySessionRepo.getStayServicesByStayId(
        this.$route.params.stayId,
        ctx.filter || "",
        ctx.currentPage,
        ctx.perPage,
        this.userStore.state.token
      );
      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      const mappedRows = result.data.data[0].rows.map((e) => {
        return {
          ...e,
          serviceName: e.services.name ? e.services.name.en : null,
        };
      });
      this.total =
        result.data.data[0].total.length && result.data.data[0].total[0].count;
      return mappedRows;
    },
  },
};
</script>

<style scoped>
.editStaySesstionBtn {
  cursor: pointer;
  color: #3897b6;
}
</style>
  