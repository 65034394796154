import axios from "axios"

/*
model from backend
export interface PersonnelModel {
  fullname: {en: string, ar: string};
  jobs?: string[];
  description?: string
  visible?: boolean;
  deleted?: boolean;
  last_updated_by: string;
  last_updated: Date;
} */

export const create = (item, token) => {
  return axios.post(process.env.VUE_APP_API_URL + `personnel/`, {
    fullname: item.fullname,
    media_id: item.media_id,
    description: item.description,
    jobs: item.jobs,
    visible: item.visible || false,

  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  return axios.put(process.env.VUE_APP_API_URL + `personnel/` + id, {
    fullname: item.fullname,
    description: item.description,
    jobs: item.jobs,
    visible: item.visible || false,
  }, {
      headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `personnel/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const get = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `personnel/?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getAdvisors = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `personnel/advisorList`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `personnel/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

/*export const getByType = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `categories/type/${type}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}*/
