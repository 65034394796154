<template>
    <div>
      <base-header
      v-if="!isUpdate()"
      class="pb-6">
        <b-row align-v="center" class="py-4">
          <b-col lg="6" cols="7">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
              <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
            </nav>
          </b-col>
        </b-row>
      </base-header>
      <b-container fluid class="mt--6">
   <b-card>
  
        <b-overlay :show="isBusy">
         
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
              <h6 class="heading-small text-muted mb-4">Basic Information</h6>
              <b-form
                class="needs-validation"
                @submit.prevent="handleSubmit(itemFormSubmit)"
                novalidate
              >
                
                <div class="">
                  <b-row>
                    <b-col lg="6">
                    <base-input
                    
                        type="text"
                        :name="'Email'"
                        label="Email"
                        :required="true"
                        placeholder="Email"
                        v-model="itemForm.email"
                      >
                      </base-input>
                      </b-col>  
                  </b-row>
    <b-row>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'First Name - English'"
                        label="First Name - English"
                        :required="true"
                        placeholder="First Name - English"
                        v-model="itemForm.first_name.en"
                      >
                      </base-input>
                    </b-col>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'First Name - Arabic'"
                        label="First Name - Arabic"
                        :required="true"
                        placeholder="First Name - Arabic"
                        v-model="itemForm.first_name.ar"
                      >
                      </base-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'Last Name - English'"
                        label="Last Name - English"
                        :required="true"
                        placeholder="Last Name - English"
                        v-model="itemForm.last_name.en"
                      >
                      </base-input>
                    </b-col>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'Last Name - Arabic'"
                        label="Last Name - Arabic"
                        :required="true"
                        placeholder="Last Name - Arabic"
                        v-model="itemForm.last_name.ar"
                      >
                      </base-input>
                    </b-col>
                  </b-row>
  
                  <b-row>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'Title - English'"
                        label="Title - English"
                        :required="true"
                        placeholder="Title - English"
                        v-model="itemForm.title.en"
                      >
                      </base-input>
                    </b-col>
                    <b-col lg="6">
                      <base-input
                        type="text"
                        :name="'Title - Arabic'"
                        label="Title - Arabic"
                        :required="true"
                        placeholder="Title - Arabic"
                        v-model="itemForm.title.ar"
                      >
                      </base-input>
                    </b-col>
                  </b-row>
  
                  <b-row>
                    <b-col lg="6">
                              <base-datetime-input
                    :required="false"
                    name="DOB"
                    label="DOB"
                    v-model="itemForm.dob"
                    >
                    </base-datetime-input>

                    
                    </b-col>
  
                    <b-col lg="6">
                      <base-input
                        type="select"
                        :required="true"
                        label="Nationality"
                        :name="'Nationality'"
                        placeholder="Select nationality"
                        v-model="itemForm.nationality"
                        :options="countries"
                        :key-field="'code'"
                        :value-field="'code'"
                        :text-field="'name'"
                      ></base-input>
                    </b-col>
  
                  </b-row>
  
                  <b-row>
                    <b-col lg="6">
                      <base-input
                        type="select"
                        :required="true"
                        label="Communication language"
                        :name="'language'"
                        placeholder="Select language"
                        v-model="itemForm.communication_language"
                        :options="languages"
                        :key-field="'code'"
                        :value-field="'code'"
                        :text-field="'name'"
                      ></base-input>
                    </b-col>
  
                  </b-row>
                </div>
  
                <base-button type="primary" native-type="submit" class="my-4">
                  {{ isUpdate() ? "Update Information" : "Create" }}
                </base-button>
              </b-form>
            </validation-observer>
        
        </b-overlay>
  
   </b-card>
   </b-container>
  
    </div>
  
  
  
  
  
    
  </template>
  <script>
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";

  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import * as routeNames from "@/routes/route-names"

  import * as GuestRepo from "@/api-repo/guests";
  
  import { handleError, successMessage } from "@/util/error-handler";
  import {getCountriesList, getLanguages} from '@/util/countries'
  
  export default {
    name: "guest-edit",
    inject: ["userStore"],
    components: {
      RouteBreadCrumb,
      baseDatetimeInput
    },
    props: ["type"],
    data() {
      return {
        
        countries: getCountriesList(),
        languages: getLanguages(),
        isBusy: false,
        dates: {
          simple: new Date(),
          range: "2019-04-19 to 2019-04-24",
        },
        itemForm: {
          first_name: { en: null, ar: null },
          last_name: { en: null, ar: null },
          title: { en: null, ar: null },
          dob: null,
          email: null,
          nationality: null,
          communication_language: null
        },
      };
    },
  
    created() {
      this.getInitData();
      if (this.isUpdate()) {
        this.getData();
      }
    },
  
    methods: {
generateBreadCrumb() {
        return [{label: "Guests", route: routeNames.routeGuests},{label: "Add Guest"}];
      },
      getInitData: async function () {},
  
      getData: async function () {
        try {
          this.isBusy = true;
          let result = await GuestRepo.getById(
            this.$route.params.id,
            this.userStore.state.token
          );
        
          this.itemForm.first_name = result.data.data.first_name;
          this.itemForm.last_name = result.data.data.last_name;
          this.itemForm.title = result.data.data.title;
          this.itemForm.dob =  result.data.data.dob;
          this.itemForm.nationality =  result.data.data.nationality;
          this.itemForm.communication_language =  result.data.data.communication_language;
        } catch (e) {
          handleError(e);
        } finally {
          this.isBusy = false;
        }
      },
  
      isUpdate: function () {
        return this.$route.params.id != null;
      },
      itemFormSubmit: async function () {
        const isValid = await this.$refs.formValidator.validate();
        if (isValid) {
          //this.itemForm.visible = this.itemForm.visible;
          const objectForSubmission = {
            ...this.itemForm
          };
          if (!this.isUpdate()) {
            try {
              console.log("CREATE")
              this.isBusy = true;
              let res = await GuestRepo.create(
                objectForSubmission,
                this.userStore.state.token
              );
              successMessage("Item created successfully");
              this.$router.replace({ name: routeNames.routeGuestMain, params: {id: res.data.data.insertedId } });
            
            } catch (e) {
              console.log(e)
              handleError(e);
            } finally {
              this.isBusy = false;
            }
          } else {
            try {
              this.isBusy = true;
              await GuestRepo.update(
                this.$route.params.id,
                objectForSubmission,
                this.userStore.state.token
              );
              successMessage("Item updated successfully");
            } catch (e) {
              handleError(e);
            } finally {
              this.isBusy = false;
            }
          }
        }
      },
    },
  };
  </script>
  