import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

 // api/surveys/guest/:guestId

  export const getAllSurveys =  (token, guestId) => {
    return axios.get(`${API_URL}surveys/guest/${guestId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  export const getById = (id, token) => {
    console.log(`${API_URL}surveys/${id}`)
    return axios.get(`${API_URL}surveys/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  export const deleteR = (id, token) => {
    return axios.delete(`${API_URL}surveys/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
