import axios from "axios"

/*
model from backend
export interface PersonnelModel {
  fullname: {en: string, ar: string};
  jobs?: string[];
  description?: string
  visible?: boolean;
  deleted?: boolean;
  last_updated_by: string;
  last_updated: Date;
} */

export const create = (item, token) => {
  const data = {
    ...item
  }
  return axios.post(process.env.VUE_APP_API_URL + `guest/`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (id, item, token) => {
  const data = {
   ...item
  }
  return axios.put(process.env.VUE_APP_API_URL + `guest/` + id, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const updatePassword = (id, password, token) => {
  const data = {
   password
  }
  return axios.put(process.env.VUE_APP_API_URL + `guest/` + id  + '/changePassword', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `guest/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const get = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `guest/?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getList = (token) => {
  return axios.get(process.env.VUE_APP_API_URL + `guest/list`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `guest/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


/*export const getByType = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `categories/type/${type}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}*/
