var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',_vm._b({attrs:{"rules":_vm.required ? 'required' : '',"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('b-form-group',{class:{ 'mb-0': _vm.nomargin }},[_vm._t("label",function(){return [(_vm.label)?_c('label',{class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('span',[_vm._v(" *")]):_vm._e()]):_vm._e()]}),_vm._t("default",function(){return [_c('b-input-group',{staticClass:"base-calendar-input-group"},[_c('b-form-input',{class:[
              { 'is-valid': valid && validated && _vm.successMessage },
              { 'is-invalid': invalid && validated } ],attrs:{"id":"example-input","type":"text","placeholder":"YYYY-MM-DD","autocomplete":"off"},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","right":"","aria-controls":"example-input"},on:{"show":_vm.shown,"context":_vm.onContext},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}})],1)],1),_vm._t("success",function(){return [(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]}),_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]})]},null,_vm.slotData)],2)]}}],null,true)},'validation-provider',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }