import axios from "axios"

/*
model from backend
export interface PersonnelModel {
  fullname: {en: string, ar: string};
  jobs?: string[];
  description?: string
  visible?: boolean;
  deleted?: boolean;
  last_updated_by: string;
  last_updated: Date;
} */

export const create = (guestId, item, token) => {
  const data = {
    guestId: guestId,
    checkin: item.checkin,
    checkout: item.checkout,
    advisorId: item.advisorId,
    retreatId: item.retreatId,
    guests: item.guests,
  }
  return axios.post(process.env.VUE_APP_API_URL + `stays/guest`, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const update = (stayId, guestId, item, token) => {
  const data = {
    checkin: item.checkin,
    checkout: item.checkout,
    advisorId: item.advisorId,
    retreatId: item.retreatId,
    guestId: guestId,
    guests: item.guests,
  }
 
  http://localhost:3003/api/stays/6374b80a85e83cede45399e3
  return axios.put(process.env.VUE_APP_API_URL + `stays/` + stayId, data, {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  })
}

export const getById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `stays/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getStaysByGuestId = (guestId, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `stays/guest/${guestId}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getStaysByGuestIdShortlist = (guestId, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `stays/guest/${guestId}/list`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const getStays = (search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `stays?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const deleteR = (id, token) => {
  return axios.delete(process.env.VUE_APP_API_URL + `stays/${id}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

/*export const getByType = (type, search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `categories/type/${type}?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}*/
