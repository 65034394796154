import axios from "axios"
 
  export const update = (key, item, token) => {
    return axios.put(process.env.VUE_APP_API_URL + `generic-content/` + key, {
      ...item
    }, {
        headers: {
        Authorization: 'Bearer ' + token,
      }
    })
  }
 
  export const get = (key, token) => {
    return axios.get(process.env.VUE_APP_API_URL + `generic-content/${key}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }
 