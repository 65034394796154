<template>
  <div>
 
    
     
    <label v-if="label" class="form-control-label">
            {{ label }}<span v-if="required">&nbsp;*</span>
          </label>
    <div  class="d-flex align-items-start justify-content-start">
        <div  v-for="(item, index) in value" :key="index" class="m-1 d-flex flex-column justify-content-center align-items-center">
           <media-input @remove="deleteImage(index)"
                      :format="format"
                      :name="name + index"
                      label=""
                      :guestId="guestId"
                      :nomargin='true'
                      :required="true"
                      v-model="value[index]"
                      >
                    </media-input>
                   

        </div>
        <div  style="width: 100px; height: 100px;cursor:pointer" class="m-1 rounded border-gray border-1 border d-flex align-items-center justify-content-center"  @click="addItem()" >
          <i class="fa fa-plus"></i>
        </div>

    </div>

    <validation-provider
      rules="min_value:1"
      :name="name"
      v-bind="$attrs"
      v-slot="{ errors, valid, invalid, validated }"
    >
      <b-form-group >
        
       
        <div>
          <slot v-bind="slotData">
          
       
            <input
              
              :value="value.length"
              hidden="true"
              v-on="listeners"
              v-bind="$attrs"
              :valid="valid"
              :required="required"
              class="form-control"
              :class="[
                { 'is-valid': valid && validated && successMessage },
                { 'is-invalid': invalid && validated },
                
              ]"
            />
          </slot>
          <slot name="infoBlock"></slot>
        </div>
        <slot name="success">
          <div
            class="valid-feedback"
            v-if="valid && validated && successMessage"
          >
            {{ successMessage }}
          </div>
        </slot>
        <slot name="error">
          <div v-if="errors[0]" class="invalid-feedback" style="display: block">
             At least one image is required
          </div>
        </slot>
      </b-form-group>
    </validation-provider>

   
    

    
  </div>
</template>
<style scoped>

</style>

<script>
import { extend } from "vee-validate";
//import { required, email, min } from "vee-validate/dist/rules";



extend('galleryLengthRule', value => {
  console.log(value.length)
  return value.length > 0;
});


import MediaInput from "@/components/Inputs/MediaInput";

export default {
  components: {
    MediaInput,
 
  },
  inheritAttrs: false,
  name: "media-gallery-input",
  inject: ["userStore"],
  props: {
    format: {
      type: String,
      default: 'image'
    },
    guestId:{
      type: String,
      default: null
    },
    required: {
      type: Boolean
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    successMessage: {
      type: String,
      description: "Input success message",
      default: "",
    },
   
    value: {
      type: [Array],
      description: "Input value",
    },
   
   
   
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
  },
  data() {
    return {
      //media: null,
     
 
     
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      };
    },
    slotData() {
      return {
        error: this.error,
        ...this.listeners,
      };
    },
  },
  created() {
    
  },
  methods: {
    deleteImage(index) {
      let value = this.value;
      value.splice(index, 1);
      this.$emit("input", value);    
    },
    addItem() {
      let value = this.value;
       value.push("")
      this.$emit("input", value);     
    },
  },
};
</script>
<style>
</style>
