<template>
  <div>
    <b-overlay :show="isBusy">
      <b-card class="pt-0">

        <b-card-header class="pt-0 pl-0 border-0">
          <b-row>
            <b-col lg="1">
              <a role="button" @click="$router.go(-1)" class="small pointer">
                <i class="mr-1 fas fa-angle-left" />Stays
              </a>
            </b-col>
            <b-col lg="8">
              <div class="d-flex">
                <div>
                  <h2>
                    {{
                      (itemForm.retreat &&
                        itemForm.retreat.name &&
                        itemForm.retreat.name.en) ||
                      "..."
                    }}
                  </h2>
                  <h4>
                    {{ dateformatter(itemForm.checkin) }} -
                    {{ dateformatter(itemForm.checkout) }}
                  </h4>
                  <h4>
                    {{
                      (itemForm.advisor &&
                        itemForm.advisor.fullname &&
                        itemForm.advisor.fullname.en) ||
                      "..."
                    }}
                  </h4>
                </div>

                <div class="ml-4 d-flex align-items-end">
                  <base-button
                    @click="editModal = true"
                    size="sm"
                    type="primary"
                    ><i class="fas fa-pen"></i
                  ></base-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <hr class="m-0 mb-3" />
        <div class="d-flex mb-3 w-100 justify-content-between">
          <div>
            <base-button
              size="sm"
              @click="toListMode()"
              :type="!calendarMode ? 'primary' : 'grey'"
              ><i class="fas fa-list" />List</base-button
            >

            <base-button
              size="sm"
              @click="toCalendarMode()"
              :type="calendarMode ? 'primary' : 'grey'"
              ><i class="fas fa-calendar" />Calendar</base-button
            >
          </div>
          <div v-if="calendarMode" class="text-lg-right">
            <a
              href="#"
              @click.prevent="prev"
              class="fullcalendar-btn-prev btn btn-sm btn-primary"
            >
              <i class="fas fa-angle-left"></i>
            </a>
            <a
              href="#"
              @click.prevent="next"
              class="fullcalendar-btn-next btn btn-sm btn-primary"
            >
              <i class="fas fa-angle-right"></i>
            </a>
          </div>
          <div v-else>
            <base-button
              @click="editSessionModal = true"
              size="sm"
              type="primary"
              ><i class="fas fa-plus" />Add Session</base-button
            >
          </div>
        </div>

        <div v-if="calendarMode" class="card-body p-0 card-calendar-body">
          <div id="fullCalendar"></div>
        </div>
        <div v-else>
          <GuestStaySessions @refresh="refreshData" v-if="reRenderSessions" />
        </div>
      </b-card>
    </b-overlay>

    <ModalGuestStayInfoEdit
     @closeModal="closeModal" 
     @refresh="getData"
     :show-modal="editModal" />

    <ModalGuestStaySessionInfoEdit
      ref="modal"
      :staySessionId="staySessionId"
      :passedDatetimeInfo="passedDatetimeInfo"
      @refresh="refreshData"
      @closeModal="closeSessionModal"
      :show-modal="editSessionModal"
    />
  </div>
</template>

 

<script>
import Vue from "vue";
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { dateformatter } from "@/util/dateformatter";
import * as StayRepo from "@/api-repo/stays";
import ModalGuestStayInfoEdit from "./Modal_GuestStayInfoEdit";
import ModalGuestStaySessionInfoEdit from "./Modal_GuestStaySessionInfoEdit.vue";
import GuestStaySessions from "./GuestStaySessions.vue";
import { handleError, successMessage } from "@/util/error-handler";

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

import interactionPlugin from "@fullcalendar/interaction";
import timeGridDay from "@fullcalendar/timegrid";
import BaseButton from "../../../components/Base/BaseButton.vue";
import * as GuestStaySessionRepo from "@/api-repo/staysessions";

const today = new Date();
const y = today.getFullYear();
const m = today.getMonth();
const d = today.getDate();
var calendar;

export default {
  name: "stay-edit",
  inject: ["userStore"],
  components: {
    ModalGuestStayInfoEdit,
    ModalGuestStaySessionInfoEdit,
    RouteBreadCrumb,
    BaseButton,
    GuestStaySessions,
  },
  props: ["type"],
  data() {
    return {
      events: [],
      passedDatetimeInfo: null,
      editModal: false,
      reRenderSessions: true,
      editSessionModal: false,
      calendarMode: false,
      isBusy: false,
      startCalendarDate: null,
      endCelanderDate:null,
      staySessionId: null,

      itemForm: {
        advisorId: null,
        retreatId: null,
        guests: null,
        checkin: null,
        checkout: null,
        retreat: null,
        advisor: null,
      },
    };
  },

  created() {
    this.getInitData();

    this.getData();
  },
  mounted() {},
  /*

media_id: 
    title: {en: , ar: }
    text: {en: , ar: }
    target_id: 
    target_type: 
    hidden: 
    tags: []
  */
  methods: {
    closeModal() {
      this.editModal = false;
    },

    closeSessionModal() {
      this.editSessionModal = false;
    },

    refreshData() {
      this.reRenderSessions = false;
      this.$nextTick(() => {
        this.reRenderSessions = true;
      });
      this.getData();
    },

    dateformatter: dateformatter,
    generateBreadCrumb() {
      return [{ label: "media" }];
    },
    initCalendar() {
      if (!this.calendarMode) return;
      var calendarEl = document.getElementById("fullCalendar");

      calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridDay, interactionPlugin],
        selectable: true,
        headerToolbar: false,
        initialView: "timeGridWeek",
        slotDuration: "00:15:00",
        minTime: "05:00:00",
        slotMinTime: "05:00:00",
        maxTime: "24:00:00",
        slotMaxTime: "24:00:00",
        validRange: {
          start: this.startCalendarDate,
          end: this.endCelanderDate,
        },
        allDaySlot: false,

        select: (e) => {
          this.passedDatetimeInfo = e.start;
          this.openNew();
        },
        eventClick: (e) => {
          this.passedDatetimeInfo = null;
          this.openEdit(e.event.id);
        },
        events: this.events,
      });
      calendar.render();
    },
    changeView(newView) {
      calendar.changeView(newView);
      calendar.view.title;
    },
    next() {
      calendar.next();
    },
    prev() {
      calendar.prev();
    },
    toCalendarMode() {
      this.calendarMode = true;
      Vue.nextTick(() => {
        this.initCalendar();
      });
    },
    toListMode() {
      this.calendarMode = false;
      calendar.destroy();
      //calendar.render();
    },
    getInitData: async function () {},

    buildEvents(sessions) {
      /*   {
          id: 999, // for repeated stuff
          title: "Repeating Event",
          start: new Date(y, m, d , 6, 0),
          allDay: false,
          className: "event-orange",
        }, */
      let events = sessions.map((e) => {
        let duration = e.services
          ? e.services.duration
            ? e.services.duration
            : 0
          : 0;
        let datetime = new Date(e.datetime);
        let endtime = new Date(e.datetime);
        endtime.setMinutes(datetime.getMinutes() + duration)

        let newEvent = {
          id: e._id,
          title: e.services ? e.services.name.en : null,
          start: new Date(datetime),
          end: endtime,
          allDay: false,
          className:
            e.services.service_type === "activity"
              ? "event-default"
              : "event-green",
        };
           if(e.notes === 'sgsgs'){
            console.log(typeof duration)
            console.log(newEvent)
        }
        return newEvent;
      });

      this.events = [...events];
    },

    reloadData() {
      this.$refs.modal.refreshData();
    },
    openEdit(id) {
      this.staySessionId = id;
      setTimeout(() => {
        this.editSessionModal = true;
        this.reloadData();
      }, 500);
    },

    openNew() {
      this.staySessionId = null;
      setTimeout(() => {
        this.editSessionModal = true;
        this.$refs.modal.initiateDateTime();
      }, 500);
    },

    getData: async function () {
      try {
        this.isBusy = true;
        let result = await StayRepo.getById(
          this.$route.params.stayId,
          this.userStore.state.token
        );

        let sessionsResult = await GuestStaySessionRepo.getStayServicesByStayId(
          this.$route.params.stayId,
          null,
          null,
          null,
          this.userStore.state.token
        );
        let sessions = sessionsResult.data.data[0].rows;

        this.buildEvents(sessions);
        if (this.calendarMode) {
          this.calendarMode = false;
          this.$nextTick(() => {
            this.toCalendarMode();
          });
        }
        this.startCalendarDate = result.data.data.checkin;
        this.endCelanderDate = result.data.data.checkout;
        this.itemForm.checkin = result.data.data.checkin;
        this.itemForm.checkout = result.data.data.checkout;
        this.itemForm.advisorId = result.data.data.advisorId;
        this.itemForm.retreatId = result.data.data.retreatId;
        this.itemForm.retreat = result.data.data.retreat;
        this.itemForm.advisor = result.data.data.advisor;
        this.itemForm.guests = result.data.data.guests;
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>


<style>
.event-green {
  background-color: green;
}
</style>