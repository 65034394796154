<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb
              :crumbs="generateBreadCrumb()"
            ></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-overlay :show="isBusy">
        <b-card no-body>
          <b-card-header class="border-0">
            <b-row>
              <b-col cols="5">
                <base-datetime-input
                  @input="forceRerender"
                  :required="true"
                  name="Date"
                  label="Date"
                  v-model="datetime"
                >
                </base-datetime-input>
              </b-col>

               <b-col cols="5">
                    <base-multi-select
                      @change="changedDate"
                      v-if="mappedDates.length>0"
                      ref="selectedDate"
                      v-model="datetimeObj"
                      :options="mappedDates"
                      :multiple="false"
                      placeholder="Session dates"
                      label="Session Dates"
                      track-by="value"
                    >
                    </base-multi-select>

            
              </b-col>

              <b-col cols="2">
                <!--   <form>
                  <b-form-group class="mb-0">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <i class="fas fa-search" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="search"
                        placeholder="Search"
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </form> -->
              </b-col>
            </b-row>
          </b-card-header>
          <b-table
            v-if="renderComponent"
            striped
            class="w-100 text-capitalize"
            header-row-class-name="thead-light"
            :busy.sync="isBusy"
            :filter="search"
            :per-page="perPage"
            :currentPage="currentPage"
            :items="provider"
            :fields="fields"
          >
        
            <template #cell(actions)="row">
                 <router-link
                :to="{
                  name: 'GuestStayEdit',
                  params: { id: row.item.guests._id, stayId: row.item.stayId },
                }"
                class="p-2"
                ><i class="fa-lg fas fa-arrow-right"></i
              ></router-link>
            </template>
            <template #cell(hidden)="row">
              <span size="" class="badge badge-dot mr-4">
                <i v-if="row.item.hidden" class="bg-warning"></i>
                <i v-else class="bg-success"></i>
                <span class="status">{{
                  row.item.hidden ? "Hidden" : "Visible"
                }}</span>
              </span>
            </template>
          </b-table>
          <b-card-footer class="py-4 d-flex justify-content-end">
            <base-pagination
              @change="reloadRows"
              v-model="currentPage"
              :per-page="perPage"
              :total="total"
            ></base-pagination>
          </b-card-footer>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
  
  <script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as Repo from "@/api-repo/staysessions";
import { fullname } from "@/util/fullname";
import { capitalize } from "@/util/capitalize";
import FileInput from "@/components/Inputs/FileInput.vue";
import { booleanFormatter } from "@/util/booleanFormatter";
import { dateformatter, dateformatterWithTime } from "@/util/dateformatter";
import DeleteItemButton from "@/components/Base/DeleteItemButton.vue";
import { handleError, successMessage } from "@/util/error-handler";
import baseDatetimeInput from "@/components/Inputs/BaseDateTimeInput";
import BaseMultiSelect from "@/components/Inputs/BaseMultiSelect.vue";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    FileInput,
    DeleteItemButton,
    baseDatetimeInput,
    BaseMultiSelect
  },

  data() {
    return {
      guestId: this.$route.params.id,
      showList: false,
      editSessionModal: false,
      staySessionId: null,
      renderComponent: true,
      isBusy: false,
      currentPage: 1,
      datetime: dateformatter(new Date()),
      datetimeObj: null,
      perPage: 20,
      mappedDates: [],
      total: 1,
      rows: [],
      search: null,
      fields: [
        { key: "guestName", label: "Guest" },
        { key: "serviceName", label: "Treatment/Activity" },
        { key: "status", label: "Status" },
        {
          key: "datetime",
          label: "Start datetime",
          formatter: dateformatterWithTime,
        },
        {
          key: "endDatetime",
          label: "End datetime",
          formatter: dateformatterWithTime,
        },
        {
          key: "last_updated",
          label: "Last updated",
          formatter: dateformatter,
        },
        { key: "last_updated_by", label: "By" },
        { key: "actions", label: "" },
      ],
    };
  },
  computed: {},

 async  mounted() {
  let res = await Repo.getUniqueDates(this.userStore.state.token)
  let data = res.data.data;
  this.mappedDates.push({name:null, value: null});
  let mappedDates = data.map(date => {return {value: dateformatter(date), name: dateformatter(date) }})
  this.mappedDates = [...this.mappedDates, ...mappedDates]
  },

  methods: {
    reloadData() {
      this.$refs.modal.refreshData();
    },

    changedDate(){
      this.datetime = this.datetimeObj.value;
      this.forceRerender();
    },
    openEdit(id) {
      this.staySessionId = id;
      setTimeout(() => {
        this.editSessionModal = true;
        this.reloadData();
      }, 500);
    },

    generateBreadCrumb() {
      return [{ label: "Sessions" }];
    },
    fullname,
    capitalize,

    reloadRows: function (e) {
      this.currentPage = e;
    },

    closeSessionModal() {
      this.editSessionModal = false;
    },

    forceRerender() {
       if(!this.datetime){
        return;
       }
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },


    provider: async function (ctx) {
      this.isBusy = true;
      let result = await Repo.getByDate(
        ctx.filter || "",
        ctx.currentPage,
        ctx.perPage,
        this.datetime,
        this.userStore.state.token
      );
      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      const mappedRows = result.data.data[0].rows.map((e) => {
        return {
          ...e,
          serviceName: e.services.name ? e.services.name.en : null,
          guestName: e.guests.fullname ? e.guests.fullname.en : null,
        };
      });
      this.total =
        result.data.data[0].total.length && result.data.data[0].total[0].count;

      
      // document.getElementById("__BVID__136__cell-2022-12-13_").style.backgroundColor = "lightblue";

      return mappedRows;
    },
  },
};
</script>

<style scoped>
.editStaySesstionBtn {
  cursor: pointer;
  color: #3897b6;
}
</style>
  